import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import '../form-modules.css';
import FormularioTable from '../table'
import axios, { formToJSON } from 'axios'
import Swal from 'sweetalert2'
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom'



function Formulario() {


    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);

    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const [tipoInstrumento, setTipoInstrumento] = useState('');
    const [modalidade, setModalidade] = useState('');
    const [modoDisputa, setModoDisputa] = useState('');
    const [numeroCompra, setNumeroCompra] = useState('');
    const [anoCompra, setAnoCompra] = useState('');
    const [numeroProcesso, setNumeroProcesso] = useState('');
    const [objetoCompra, setObjetoCompra] = useState('');
    const [informacaoComplementar, setInformacaoComplementar] = useState('')
    const [srp, setSrp] = useState(true);
    const [DataAberturaProposta, setDataAberturaProposta] = useState('');
    const [dataEncerramentoProposta, setDataEncerramentoProposta] = useState('');
    const [amparoLegal, setAmparoLegal] = useState('');
    const [linkSistemaOrigem, setLinkSistemaOrigem] = useState('');
    const [justificativa, setJustificativa] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [documento, setDocumento] = useState([]);
    const [dataContratacao, setDataContratacao] = useState('');
    //Adicionar items 
    const [orcamentoSigiloso, setOrcamentoSigiloso] = useState('');
    const [criterioJulgamento, setCriteriojulgamento] = useState('');
    const [materialServico, setMaterialServico] = useState('');
    const [valorUnitarioEstimado, setValorUnitarioEstimado] = useState('');
    const [incentivoProdutivoBasico, setIncentivoProdutivoBasico] = useState('');
    const [tipoBeneficio, setTipoBeneficio] = useState('');
    const [categoriaItem, setcategoriaItem] = useState('');
    const [patrimonio, setPatrimonio] = useState('');
    const [codigoRegistroImobiliario, setCodigoRegistroImobiliario] = useState('')


    const [numeroItem, setNumeroItem] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [quantidade, setQuantidade] = useState('');
    const [unidadeMedida, setUnidadeMedida] = useState('');
    const [descricao, setDescricao] = useState('');


    const urlParams = new URLSearchParams(window.location.search)

    const cnpjParams = urlParams.get("cnpj")
    const url_apiParams = urlParams.get("url_api")
    const codigo_unidadeParams = urlParams.get("codigo_unidade")


    const [cnpj, setCnpj] = useState(cnpjParams)
    const [codigoUnidade, setCodigoUnidade] = useState(codigo_unidadeParams);
   


    const handleCriterioJulgamento = (e) => {
        setCriteriojulgamento(e.target.value)
    }

    const handleCnpj = (e) => {
        setCnpj(e.target.value)
    }

    const handleJustificativa = (e) => {
        setJustificativa(e.target.value)
    }

    const handleDataContarcao = (e) => {
        setDataContratacao(e.target.value)
    }

    const handleTipoDocumento = (e) => {
        setTipoDocumento(e.target.value)
    }

    const handleDocumento = (e) => {

        setDocumento(e.target.files[0])
        console.log(e.target.files)
    }

    const handleDescricao = (e) => {
        setDescricao(e.target.value)
    }

    const handleUnidadeMedida = (e) => {
        setUnidadeMedida(e.target.value)
    }

    const handlemMaterialServico = (e) => {
        setMaterialServico(e.target.value)
    }

    const handleNumeroItem = (e) => {
        setNumeroItem(e.target.value)
    }

    const handleQuantidade = (e) => {
        setQuantidade(e.target.value)
    }

    const handleValorTotal = (e) => {
        setValorTotal(e.target.value)
    }

    const handleValorUnitarioEstimado = (e) => {
        setValorUnitarioEstimado(e.target.value)
    }

    const handleIncentivoProdutivoBasico = (e) => {
        setIncentivoProdutivoBasico(e.target.value)
    }

    const handleTipoBeneficio = (e) => {
        setTipoBeneficio(e.target.value)
    }

    const handleCategoriaItem = (e) => {
        setcategoriaItem(e.target.value)
    }

    const handlePatrimonio = (e) => {
        setPatrimonio(e.target.value)
    }

    const handleCodigoRegistroImobiliario = (e) => {
        setCodigoRegistroImobiliario(e.target.value)
    }

    const handleCodigoUnidade = (e) => {
        setCodigoUnidade(e.target.value)
    }

    const handleTipoInstrumento = (e) => {
        setTipoInstrumento(e.target.value)
    }

    const handleModalidade = (e) => {
        setModalidade(e.target.value)
    }

    const handleModoDisputa = (e) => {
        setModoDisputa(e.target.value)
    }

    const handleNumeroCompra = (e) => {
        setNumeroCompra(e.target.value)
    }

    const handleAnoCompra = (e) => {
        setAnoCompra(e.target.value)
    }

    const handleNumeroProcesso = (e) => {
        setNumeroProcesso(e.target.value)
    }

    const handleObjetoCompra = (e) => {
        setObjetoCompra(e.target.value)
    }

    const handleInformacoesComplementar = (e) => {
        setInformacaoComplementar(e.target.value)
    }

    const handleOrcamentoSigiloso = (e) => {
        setOrcamentoSigiloso(e.target.value)
    }

    const handleSrp = (e) => {
        setSrp(e.target.value)
    }

    const handleDataAberturaProposta = (e) => {
        setDataAberturaProposta(e.target.value)
        console.log(e.target.value)

    }

    const handleDataEncerramentoProposta = (e) => {
        setDataEncerramentoProposta(e.target.value)
    }

    const handleAmparoLegal = (e) => {
        setAmparoLegal(e.target.value)
    }

    const handleLinkSistemaOrigem = (e) => {
        setLinkSistemaOrigem(e.target.value)
    }

    // const itens = JSON.parse(localStorage.getItem("itens")) || [] 
    // console.log(itens)


    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        const jsonData =

        {

            codigoUnidadeCompradora: codigoUnidade,
            tipoInstrumentoConvocatorioId: tipoInstrumento,
            modalidadeId: modalidade,
            modoDisputaId: modoDisputa,
            numeroCompra: numeroCompra,
            anoCompra: anoCompra,
            numeroProcesso: numeroProcesso,
            objetoCompra: objetoCompra,
            informacaoComplementar: informacaoComplementar,
            srp: false,
            orcamentoSigiloso: orcamentoSigiloso,
            dataAberturaProposta: `${DataAberturaProposta}:00`,
            dataEncerramentoProposta: `${dataEncerramentoProposta}:00`,
            amparoLegalId: amparoLegal,
            linkSistemaOrigem: linkSistemaOrigem,
            justificativaPresencial: justificativa,
            itensCompra: items,
        	tipoDocumento: tipoDocumento
        	

        }


        const formData = new FormData();
        formData.append('documento', documento)
        const jsonBlob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });
        formData.append('compra', jsonBlob, 'data.json');

        // const instance = axios.create({
        //     baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpj}`, // Substitua pela URL do seu servidor
        //     timeout: 10000, // Tempo limite da solicitação
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'multipart/form-data',
        //         'Authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYmE3OTcxOS1lMDlkLTRmNzItODczYS1iMzJhNTlkYjU4MzciLCJleHAiOjE2OTI2NDcxMDEsImFkbWluaXN0cmFkb3IiOmZhbHNlLCJjcGZDbnBqIjoiMzAwMTYyNjQwMDAxMDIiLCJlbWFpbCI6ImNvbnRhdG9AZG9pc3JzaXN0ZW1hcy5jb20uYnIiLCJpZEJhc2VEYWRvcyI6OTQyLCJub21lIjoiRE9JU1IgU0VSVklDT1MgREUgREVTRU5WT0xWSU1FTlRPIEUgQ09OU1VMVE9SSUEgREUgU0lTVEVNQSBMVERBIC8gRG9pc1IgU2lzdGVtYXMifQ.yTJip0cpI4CkSwoE5Xt1ot0PsYEB1ip8Nl2wO63xe9770er8gR0mL0rrbMBzCb6YEfunSoIo8cxYFzwKEYL6bQ`,
        //         'Accept': '*/*',
        //         'Titulo-Documento': 'nome_do_documento',
        //         'Tipo-Documento-Id': 1, // Substitua pelo domínio permitido, se possível
        //     },
        // });

        try {
            await axios.post(`${url_apiParams}/v1/orgaos/${cnpjParams}/compras`, formData)
                .then(async res => {

                    console.log(res)


                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Contrato Cadastrado!',
                        showConfirmButton: false,
                        timer: 1500
                    })




                })
        } catch (error) {

            console.log(error)
            console.log(error.response.data.message)

            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
            })



        };


    }




    const [items, setItems] = useState([]);


    const [newItem, setNewItem] = useState({
        numeroItem: +{},
        materialOuServico: materialServico,
        tipoBeneficioId: tipoBeneficio,
        incentivoProdutivoBasico: incentivoProdutivoBasico,
        descricao: descricao,
        quantidade: +{},
        unidadeMedida: unidadeMedida,
        orcamentoSigiloso: orcamentoSigiloso,
        valorUnitarioEstimado: +{},
        valorTotal: +{},
        criterioJulgamentoId: criterioJulgamento,
        itemCategoriaId: 3,
        patrimonio: 'string',
        codigoRegistroImobiliario: codigoRegistroImobiliario
    });

    const NumeroItem = Number(newItem.numeroItem)
    const Quantidade = Number(newItem.quantidade)
    const ValorUnitario = Number(newItem.valorUnitarioEstimado)
    const ValorTotal = Number(newItem.valorTotal)
    const ItemCategoria = Number(newItem.itemCategoriaId)
    console.log(NumeroItem, Quantidade, ValorUnitario, ValorTotal, ItemCategoria)

    const addItem = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated2(true);


        if (await Object.values(newItem).every((column) => column.toString() !== '')) {
            setItems([...items, newItem]);
            setNewItem({
                numeroItem: NumeroItem,
                materialOuServico: materialServico,
                tipoBeneficioId: tipoBeneficio,
                incentivoProdutivoBasico: incentivoProdutivoBasico,
                descricao: descricao,
                quantidade: Quantidade,
                unidadeMedida: unidadeMedida,
                orcamentoSigiloso: orcamentoSigiloso,
                valorUnitarioEstimado: ValorUnitario,
                valorTotal: ValorTotal,
                criterioJulgamentoId: criterioJulgamento,
                itemCategoriaId: 3,
                patrimonio: 'string',
                codigoRegistroImobiliario: codigoRegistroImobiliario
            });

            await Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Item Adicionado!',
                showConfirmButton: false,
                timer: 1500
            })
    
        }

        console.log(items)

        

    };

    const handleSubmitModal = (event) => {
        event.preventDefault();
        console.log('Itens submetidos:', items);
        // Aqui você pode enviar os itens para o servidor usando fetch ou axios, por exemplo.
    };


    const handleColumnChange = (columnName, value) => {
        setNewItem((prevItem) => ({
            ...prevItem,
            [columnName]: value
        }));
    };


    function handleRemove(deleteItem) {

        console.log('item', deleteItem)
        const filterd = items.filter(item => item.numeroItem !== deleteItem);
        console.log(filterd)
        setItems(filterd)



    }







    return (
        <div className="form">



            <div className='Dados-Title'>

                <h1>Inserir Compra</h1>
                <h3> CÂMARA MUNICIPAL DE CIDADE - BAHIA  <img src='' /> </h3>

            </div>

            <hr />

            <div className='form-create'>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Modalidade <b>*</b></Form.Label>
                            <Form.Select required aria-label="Default select example" onChange={handleModalidade} value={modalidade}>
                                <option selected value={""}>Selecione</option>
                                <option value="1"> Leilão - Eletrônico</option>
                                <option value="2"> Diálogo Competitivo</option>
                                <option value="3"> Concurso</option>
                                <option value="4"> Concorrência - Eletrônica</option>
                                <option value="5"> Concorrência - Presencial</option>
                                <option value="6"> Pregão - Eletrônico</option>
                                <option value="7"> Pregão - Presencial</option>
                                <option value="8"> Dispensa de Licitação</option>
                                <option value="9"> Inexigibilidade</option>
                                <option value="10"> Manifestação de Interesse</option>
                                <option value="11"> Pré-qualificação</option>
                                <option value="12"> Credenciamento</option>
                                <option value="13"> Leilão - Presencial</option>
								<option value="14"> Inaplicabilidade da Licitação</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Amparo Legal <b>*</b></Form.Label>
                            <Form.Select required aria-label="Default select example" onChange={handleAmparoLegal} value={amparoLegal}>
                                <option selected value={""}>Selecione</option>

                                <option value="1">Lei 14.133/2021, Art. 28, I</option>
                                <option value="2">Lei 14.133/2021, Art. 28, II</option>
                                <option value="3">Lei 14.133/2021, Art. 28, III</option>
                                <option value="4">Lei 14.133/2021, Art. 28, IV</option>
                                <option value="5">Lei 14.133/2021, Art. 28, V</option>
                                <option value="6">Lei 14.133/2021, Art. 74, I</option>
                                <option value="7">Lei 14.133/2021, Art. 74, II</option>
                                <option value="8">Lei 14.133/2021, Art. 74, III, a</option>
                                <option value="9">Lei 14.133/2021, Art. 74, III, b</option>
                                <option value="10">Lei 14.133/2021, Art. 74, III, c</option>
                                <option value="11">Lei 14.133/2021, Art. 74, III, d</option>
                                <option value="12">Lei 14.133/2021, Art. 74, III, e</option>
                                <option value="13">Lei 14.133/2021, Art. 74, III, f</option>
                                <option value="14">Lei 14.133/2021, Art. 74, III, g</option>
                                <option value="15">Lei 14.133/2021, Art. 74, III, h</option>
                                <option value="16">Lei 14.133/2021, Art. 74, IV</option>
                                <option value="17">Lei 14.133/2021, Art. 74, V</option>
                                <option value="18">Lei 14.133/2021, Art. 75, I</option>
                                <option value="19">Lei 14.133/2021, Art. 75, II</option>
                                <option value="20">Lei 14.133/2021, Art. 75, III, a</option>
                                <option value="21">Lei 14.133/2021, Art. 75, III, b</option>
                                <option value="22">Lei 14.133/2021, Art. 75, IV, a</option>
                                <option value="23">Lei 14.133/2021, Art. 75, IV, b</option>
                                <option value="24">Lei 14.133/2021, Art. 75, IV, c</option>
                                <option value="25">Lei 14.133/2021, Art. 75, IV, d</option>
                                <option value="26">Lei 14.133/2021, Art. 75, IV, e</option>
                                <option value="27">Lei 14.133/2021, Art. 75, IV, f</option>
                                <option value="28">Lei 14.133/2021, Art. 75, IV, g</option>
                                <option value="29">Lei 14.133/2021, Art. 75, IV, h</option>
                                <option value="30">Lei 14.133/2021, Art. 75, IV, i</option>
                                <option value="31">Lei 14.133/2021, Art. 75, IV, j</option>
                                <option value="32">Lei 14.133/2021, Art. 75, IV, k</option>
                                <option value="33">Lei 14.133/2021, Art. 75, IV, l</option>
                                <option value="34">Lei 14.133/2021, Art. 75, IV, m</option>
                                <option value="35">Lei 14.133/2021, Art. 75, V</option>
                                <option value="36">Lei 14.133/2021, Art. 75, VI</option>
                                <option value="37">Lei 14.133/2021, Art. 75, VII</option>
                                <option value="38">Lei 14.133/2021, Art. 75, VIII</option>
                                <option value="39">Lei 14.133/2021, Art. 75, IX</option>
                                <option value="40">Lei 14.133/2021, Art. 75, X</option>
                                <option value="41">Lei 14.133/2021, Art. 75, XI</option>
                                <option value="42">Lei 14.133/2021, Art. 75, XII</option>
                                <option value="43">Lei 14.133/2021, Art. 75, XIII</option>
                                <option value="44">Lei 14.133/2021, Art. 75, XIV</option>
                                <option value="45">Lei 14.133/2021, Art. 75, XV</option>
                                <option value="46">Lei 14.133/2021, Art. 75, XVI</option>
                                // <option value="47">Lei 14.133/2021, Art. 78, I</option>
                                <option value="48">Lei 14.133/2021, Art. 78, II</option>
                                <option value="49">Lei 14.133/2021, Art. 78, III</option>
                                <option value="50">Lei 14.133/2021, Art. 74, caput</option>
                                <option value="51">Lei 14.284/2021, Art. 29, caput</option>
                                <option value="52">Lei 14.284/2021, Art. 24 § 1º</option>
                                <option value="53">Lei 14.284/2021, Art. 25 § 1º</option>
                                <option value="54">Lei 14.284/2021, Art. 34</option>
                                <option value="55">Lei 9.636/1998, Art. 11-C, I</option>
                                <option value="56">Lei 9.636/1998, Art. 11-C, II</option>
                                <option value="57">Lei 9.636/1998, Art. 24-C, I</option>
                                <option value="58">Lei 9.636/1998, Art. 24-C, II</option>
                                <option value="59">Lei 9.636/1998, Art. 24-C, III</option>
								<option value="60">Lei 14.133/2021, Art. 75, XVII</option>
    <option value="61">Lei 14.133/2021, Art. 76, I, a</option>
    <option value="62">Lei 14.133/2021, Art. 76, I, b</option>
    <option value="63">Lei 14.133/2021, Art. 76, I, c</option>
    <option value="64">Lei 14.133/2021, Art. 76, I, d</option>
    <option value="65">Lei 14.133/2021, Art. 76, I, e</option>
    <option value="66">Lei 14.133/2021, Art. 76, I, f</option>
    <option value="67">Lei 14.133/2021, Art. 76, I, g</option>
    <option value="68">Lei 14.133/2021, Art. 76, I, h</option>
    <option value="69">Lei 14.133/2021, Art. 76, I, i</option>
    <option value="70">Lei 14.133/2021, Art. 76, I, j</option>
    <option value="71">Lei 14.133/2021, Art. 76, II, a</option>
    <option value="72">Lei 14.133/2021, Art. 76, II, b</option>
    <option value="73">Lei 14.133/2021, Art. 76, II, c</option>
    <option value="74">Lei 14.133/2021, Art. 76, II, d</option>
    <option value="75">Lei 14.133/2021, Art. 76, II, e</option>
    <option value="76">Lei 14.133/2021, Art. 76, II, f</option>
    <option value="77">Lei 14.133/2021, Art. 75, XVIII</option>
    <option value="78">Lei 14.628/2023, Art. 4º</option>
    <option value="79">Lei 14.628/2023, Art. 12</option>
    <option value="80">Lei 14.133/2021, Art. 1º, § 2º</option>
    <option value="81">Lei 13.303/2016, Art. 27, § 3º</option>
    <option value="82">Lei 13.303/2016, Art. 28, § 3º, I</option>
    <option value="83">Lei 13.303/2016, Art. 28, § 3º, II</option>
    <option value="84">Lei 13.303/2016, Art. 29, I</option>
    <option value="85">Lei 13.303/2016, Art. 29, II</option>
    <option value="86">Lei 13.303/2016, Art. 29, III</option>
    <option value="87">Lei 13.303/2016, Art. 29, IV</option>
    <option value="88">Lei 13.303/2016, Art. 29, V</option>
    <option value="89">Lei 13.303/2016, Art. 29, VI</option>
    <option value="90">Lei 13.303/2016, Art. 29, VII</option>
    <option value="91">Lei 13.303/2016, Art. 29, VIII</option>
    <option value="92">Lei 13.303/2016, Art. 29, IX</option>
    <option value="93">Lei 13.303/2016, Art. 29, X</option>
    <option value="94">Lei 13.303/2016, Art. 29, XI</option>
    <option value="95">Lei 13.303/2016, Art. 29, XII</option>
    <option value="96">Lei 13.303/2016, Art. 29, XIII</option>
    <option value="97">Lei 13.303/2016, Art. 29, XIV</option>
    <option value="98">Lei 13.303/2016, Art. 29, XV</option>
    <option value="99">Lei 13.303/2016, Art. 29, XVI</option>
    <option value="100">Lei 13.303/2016, Art. 29, XVII</option>
    <option value="101">Lei 13.303/2016, Art. 29, XVIII</option>
    <option value="102">Lei 13.303/2016, Art. 30, caput - inexigibilidade</option>
    <option value="103">Lei 13.303/2016, Art. 30, caput - credenciamento</option>
    <option value="104">Lei 13.303/2016, Art. 30, I</option>
    <option value="105">Lei 13.303/2016, Art. 30, II, a</option>
    <option value="106">Lei 13.303/2016, Art. 30, II, b</option>
    <option value="107">Lei 13.303/2016, Art. 30, II, c</option>
    <option value="108">Lei 13.303/2016, Art. 30, II, d</option>
    <option value="109">Lei 13.303/2016, Art. 30, II, e</option>
    <option value="110">Lei 13.303/2016, Art. 30, II, f</option>
    <option value="111">Lei 13.303/2016, Art. 30, II, g</option>
    <option value="112">Lei 13.303/2016, Art. 31, § 4º</option>
    <option value="113">Lei 13.303/2016, Art. 32, IV</option>
    <option value="114">Lei 13.303/2016, Art. 54, I</option>
    <option value="115">Lei 13.303/2016, Art. 54, II</option>
    <option value="116">Lei 13.303/2016, Art. 54, III</option>
    <option value="117">Lei 13.303/2016, Art. 54, IV</option>
    <option value="118">Lei 13.303/2016, Art. 54, V</option>
    <option value="119">Lei 13.303/2016, Art. 54, VI</option>
    <option value="120">Lei 13.303/2016, Art. 54, VII</option>
    <option value="121">Lei 13.303/2016, Art. 54, VIII</option>
    <option value="122">Lei 13.303/2016, Art. 63, I</option>
    <option value="123">Lei 13.303/2016, Art. 63, III</option>
    <option value="124">Regulamento Interno de Licitações e Contratos Estatais - diálogo competitivo</option>
    <option value="125">Regulamento Interno de Licitações e Contratos Estatais - credenciamento</option>
    <option value="126">Lei 12.850/2013, Art. 3º, §1º, II</option>
    <option value="127">Lei 12.850/2013, Art. 3º, §1º, V</option>
    <option value="128">Lei 13.529/2017, Art. 5º</option>
    <option value="129">Lei 8.629/1993, Art. 17, § 3º, V</option>
    <option value="130">Lei 10.847/2004, Art. 6º</option>
    <option value="131">Lei 11.516/2007, Art. 14-A</option>
    <option value="132">Lei 11.652/2008, Art. 8º, § 2º, I</option>
    <option value="133">Lei 11.652/2008, Art. 8º, § 2º, II</option>
    <option value="134">Lei 11.759/2008, Art. 18-A</option>
    <option value="135">Lei 12.865/2013, Art. 18, § 1º</option>
    <option value="136">Lei 12.873/2013, Art. 42</option>
    <option value="137">Lei 13.979/2020, Art. 4º, § 1º</option>
    <option value="138">Lei 11.947/2009, Art. 14, 1º</option>
    <option value="139">Lei 11.947/2009, Art. 21</option>
    <option value="140">Lei 14.133/2021, Art. 79, I</option>
    <option value="141">Lei 14.133/2021, Art. 79, II</option>
    <option value="142">Lei 14.133/2021, Art. 79, III</option>

                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Modo de Disputa <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Select required aria-label="Default select example" onChange={handleModoDisputa} value={modoDisputa}>
                                    <option selected value={""}>Selecione</option>

                                    <option value="1">Aberto</option>
                                    <option value="2">Fechado</option>
                                    <option value="3">Aberto-Fechado</option>
                                    <option value="4">Dispensa Com Disputa</option>
                                    <option value="5">Não se aplica</option>
                                    <option value="6">Fechado-Aberto</option>

                                </Form.Select>

                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Tipo Instrumental Convocatório <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Select required aria-label="Default select example" onChange={handleTipoInstrumento} value={tipoInstrumento}>
                                    <option selected value={""}>Selecione</option>

                                    <option value="1">Edital</option>
                                    <option value="2">Aviso de Contratação Direta</option>
                                    <option value="3">Ato que autoriza a Contratação Direta</option>
									<option value="4">Edital de Chamamento Público</option>
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Número da Compra <b>*</b></Form.Label>
                            <Form.Control type="text" placeholder=" número gerado sistema origem" required onChange={handleNumeroCompra} value={numeroCompra} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Ano Compra <b>*</b></Form.Label>
                            <Form.Control type="number" placeholder="2023" required onChange={handleAnoCompra} value={anoCompra} />

                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Abertura de Proposta <b>*</b></Form.Label>
                            <Form.Control type="datetime-local" required onChange={handleDataAberturaProposta} value={DataAberturaProposta} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Encerramento de Proposta <b>*</b></Form.Label>
                            <Form.Control type="datetime-local" required onChange={handleDataEncerramentoProposta} value={dataEncerramentoProposta} />

                        </Form.Group>

                    </Row>
                    <Row className="mb-3">

                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Data de Contratação</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="datetime-local" required onChange={handleDataContarcao} value={dataContratacao} />

                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Tipo de Documento <b>*</b></Form.Label>
                            <Form.Select required aria-label="Default select example" onChange={handleTipoDocumento} value={tipoDocumento}>
                                <option selected value={""}>Selecione</option>
                                <option value="1">Aviso de Contratação Direta</option>
                                <option value="2">Edital</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <Form.Label>Documento <b>*</b></Form.Label>

                            <Form.Control type="file" name='documento' onChange={handleDocumento} required />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Número do Processo <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="text" placeholder="Número processo de contratação" onChange={handleNumeroProcesso} value={numeroProcesso} required />
                            </InputGroup>
                        </Form.Group>

                    </Row>
                    <Row className="mb-3" >
                        <Form.Group as={Col} md="3" controlId="validationCustom02" style={{ display: 'none' }}>
                            <Form.Label>CNPJ <b>*</b></Form.Label>
                            <Form.Control type="number" placeholder="CNPJ" required onChange={handleCnpj} value={cnpj} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom03" style={{ display: 'none' }}>
                            <Form.Label>Código de Unidade Compradora <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="text" placeholder="proprietário da contratação ou alienação" onChange={handleCodigoUnidade} value={codigoUnidade} required />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <Form.Label>Link Certamen</Form.Label>
                            <Form.Control type="text" placeholder="URL para página/portal do sistema contratação" onChange={handleLinkSistemaOrigem} value={linkSistemaOrigem} required />

                        </Form.Group>
                        <Form.Group className="mb-3" as={Col} md="3" controlId="validationCustom04">
                            <Form.Check
                                required
                                label="Sistema de Registro de Preços"
                                feedbackType="invalid"
                                onChange={handleSrp} value={srp} />
                        </Form.Group>

                    </Row>

                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Objeto da Compra <b>*</b></Form.Label>
                        <Form.Control type="text" className='options-text' rows={3} onChange={handleObjetoCompra} value={objetoCompra} required />

                    </Form.Group>


                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Informação Complementar</Form.Label>
                        <Form.Control type="text" className='options-text' rows={3} onChange={handleInformacoesComplementar} value={informacaoComplementar} required />

                    </Form.Group>

                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Justificativa</Form.Label>
                        <Form.Control type="text" className='options-text' rows={3} onChange={handleJustificativa} value={justificativa} required />

                    </Form.Group>


                    <div className='Dados-Title-itens'>

                        <h4>Itens da Compra</h4>

                    </div>

                    <hr />

                    <div className='list-button'>

                        {/* <Button variant="outline-primary">Planilha Modelo</Button>{' '}
                        <Button variant="outline-primary">Iportar itens</Button>{' '} */}
                        <Button variant="outline-primary" onClick={handleShow}>
                            Adicionar itens
                        </Button>



                    </div>







                    <div className="form-table">



                        <Table striped>
                            <thead>


                                <tr>
                                    <th>Numero Item</th>
                                    <th>Quantidade</th>
                                    <th>Valor Total</th>
                                    <th>Descrição</th>
                                    <th>Unidade Medida</th>

                                </tr>


                            </thead>


                            <tbody>

                                {items.length === 0 && <h5>Nenhum Item Adicionado!</h5>}
                                {items.map((item, itemIndex) => (
                                    <tr key={itemIndex}>

                                        <td>{item.numeroItem}</td>
                                        <td>{item.quantidade}</td>
                                        <td>{item.valorTotal}</td>
                                        <td>{item.descricao}</td>
                                        <td>{item.unidadeMedida}</td>
                                        <Button className='button-remove' onClick={() => handleRemove(item.numeroItem)} variant="danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                        </svg></Button>{' '}
                                    </tr>

                                ))}





                            </tbody>








                        </Table>

                    </div>










                    <Button type="submit">Cadastrar</Button>
                </Form>

                <Modal show={show}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Adicionar item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form onSubmit={addItem} noValidate validated={validated2}> 

                        <Row className="mb-3">

                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Criterio de Julgamento<b>*</b></Form.Label>
                                <Form.Select onChange={(e) => handleColumnChange('criterioJulgamentoId', e.target.value)}
                                    value={newItem.criterioJulgamentoId} required aria-label="Default select example"
                                >
                                    <option selected value={""}>Selecione</option>

                                    <option value="1">Menor preço</option>
                                    <option value="2">Maior desconto</option>
                                    <option value="4">Técnica e preço</option>
                                    <option value="5">Maior lance</option>
                                    <option value="6">Maior retorno econômico</option>
                                    <option value="7">Não se aplica</option>
                                    <option value="8">Melhor técnica</option>
                                    <option value="9">Conteúdo artístico</option>

                                </Form.Select>



                            </Form.Group>


                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                <Form.Label>Descrição <b>*</b></Form.Label>
                                <Form.Control type="text" placeholder="Descrição para o produto ou seviço" required
                                    onChange={(e) => handleColumnChange('descricao', e.target.value)} value={newItem.descricao} />

                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Unidade Medida <b>*</b></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control type="text" placeholder="Unidade de medida do item da contratação" required onChange={(e) => handleColumnChange('unidadeMedida', e.target.value)} value={newItem.unidadeMedida} />


                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Material ou Serviço</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Select required aria-label="Default select example" onChange={(e) => handleColumnChange('materialOuServico', e.target.value)} value={newItem.materialOuServico} >
                                        <option selected value={""}>Selecione</option>

                                        <option value="M">Material</option>
                                        <option value="S">Serviço</option>

                                    </Form.Select>

                                </InputGroup>
                            </Form.Group>


                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Numero do Item <b>*</b></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control type="number" placeholder="(único e sequencial crescente)" required
                                        onChange={(e) => handleColumnChange('numeroItem', e.target.value)} value={newItem.numeroItem} />


                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Quantidade <b>*</b></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control type="number" placeholder="Precisão de 4 dígitos decimais; Ex: 100.0000" minLength={4} required
                                        onChange={(e) => handleColumnChange('quantidade', e.target.value)} value={newItem.quantidade} />


                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Valor Total <b>*</b></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control type="number" placeholder="Precisão de 4 dígitos decimais; Ex: 100.0000" required
                                        onChange={(e) => handleColumnChange('valorTotal', e.target.value)} value={newItem.valorTotal} />


                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Valor Unitário Estimado <b>*</b></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control type="number" placeholder="Precisão de 4 dígitos decimais; Ex: 100.0000" required
                                        onChange={(e) => handleColumnChange('valorUnitarioEstimado', e.target.value)} value={newItem.valorUnitarioEstimado} />


                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Incentivo Produtivo Básico <b>*</b></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Select required aria-label="Default select example"
                                        onChange={(e) => handleColumnChange('incentivoProdutivoBasico', e.target.value)} value={newItem.incentivoProdutivoBasico} >
                                        <option selected value={""}>Selecione</option>

                                        <option value={true}>Sim</option>
                                        <option value={false}>Não</option>
                                    </Form.Select>

                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Tipo de Beneficio <b>*</b></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Select required aria-label="Default select example"
                                        onChange={(e) => handleColumnChange('tipoBeneficioId', e.target.value)} value={newItem.tipoBeneficioId} >
                                        <option selected value={""}>Selecione</option>

                                        <option value="1">Cota exclusiva para ME/EPP</option>
                                        <option value="2">Subcontratação para ME/EPP</option>
                                        <option value="3">Cota reservada para ME/EPP</option>
                                        <option value="4">Sem benefício</option>
                                        <option value="5">Não se aplica</option>

                                    </Form.Select>

                                </InputGroup>
                            </Form.Group>


                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Orçamento Sigiloso <b>*</b></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Select required aria-label="Default select example"
                                        onChange={(e) => handleColumnChange('orcamentoSigiloso', e.target.value)} value={newItem.orcamentoSigiloso} >
                                        <option selected value={""}>Selecione</option>

                                        <option value={true}>Sim</option>
                                        <option selected="selected" value={false}>Não</option>

                                    </Form.Select>

                                </InputGroup>
                            </Form.Group>

                            {/* <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Categoria do Item <b>*</b></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Select required aria-label="Default select example"
                                        onChange={(e) => handleColumnChange('itemCategoriaId', e.target.value)} value={newItem.itemCategoriaId} >
                                        <option selected value={""}>Selecione</option>

                                        <option value={1}>Bens Imóveis</option>
                                        <option value={2}>Bens Móveis</option>
                                        <option value={3}>Não se aplica</option>

                                    </Form.Select>

                                </InputGroup>
                            </Form.Group> */}


                            {/* <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Patrimonio</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control type="text" placeholder="Código de Patrimonio do Item" required
                                        onChange={(e) => handleColumnChange('patrimonio', e.target.value)} value={newItem.patrimonio} />


                                </InputGroup>
                            </Form.Group> */}

                            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                <Form.Label>Codigo Registro Imobiliário</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control type="text" placeholder="Código de Registro Imobiliário." 
                                        onChange={(e) => handleColumnChange('codigoRegistroImobiliario', e.target.value)} value={newItem.codigoRegistroImobiliario} />


                                </InputGroup>
                            </Form.Group>


                        </Row>
                        <Button type='submit' className='button-add-item' variant="primary">
                            Adicionar
                        </Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>

            </div>

        </div>
    );
}

export default Formulario;
