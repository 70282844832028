import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import '../form-modules.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'


function FormularioAtosPreco() {

    const [validated, setValidated] = useState(false);
    const [numeroRegistro, setNumeroregistro] = useState('');
    const [anoAta, setAnoAta] = useState('');
    const [dataAssinatura, setDataAssinatura] = useState('');
    const [datavigilensianicio, setDataVigilesianicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    //const [sequencialcompra, setSequencialCompra] = useState('');
    const [documento, setDocumento] = useState([]);

	const urlParams = new URLSearchParams(window.location.search)
    const cnpjParams = urlParams.get("cnpj")

    const url_apiParams = urlParams.get("url_api")
    const sequencialParams = urlParams.get("sequencial")
    const anoCompraParams = urlParams.get("anoCompra")

    const [sequencialcompra, setSequencialCompra] = useState(sequencialParams);
    const [anoCompra, setAnoCompra] = useState(anoCompraParams);
    const [cnpj, setCnpj] = useState(cnpjParams);


    const handleNumeroRegistro = (e) => {
        setNumeroregistro(e.target.value)
    }

    const handleDocumento = (e) => {

        setDocumento(e.target.files[0])
        console.log(e.target.files)
    }

    const handleAnoAta = (e) => {
        setAnoAta(e.target.value)
    }

    const handleDataAssinatura = (e) => {
        setDataAssinatura(e.target.value)
    }

    const handleCnpj = (e) => {
        setCnpj(e.target.value)
    }

    const handleDataVigilensianicio = (e) => {
        setDataVigilesianicio(e.target.value)
    }

    const handleDataFim = (e) => {
        setDataFim(e.target.value)
    }

    const handleAnoCompra = (e) => {
        setAnoCompra(e.target.value)
    }

    const handleSequencialCompra = (e) => {
        setSequencialCompra(e.target.value)
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        const jsonData =

        {
            numeroAtaRegistroPreco: numeroRegistro,
            anoAta: anoAta,
            dataAssinatura: dataAssinatura,
            dataVigenciaInicio: datavigilensianicio,
            dataVigenciaFim: dataFim,
            cnpj:cnpj,
        	anoCompra:anoCompraParams,
        	sequencialcompra:sequencialParams

        }
        

        const formData = new FormData();
        formData.append('documento', documento)
        const jsonBlob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });
        formData.append('compra', jsonBlob, 'data.json');


//         const instance = axios.create({
//             baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpj}`, // Substitua pela URL do seu servidor
//             timeout: 10000, // Tempo limite da solicitação
//             headers: {
//                 'Access-Control-Allow-Origin': '*',
//                 'Content-Type': 'multipart/form-data',
//                 'Authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYmE3OTcxOS1lMDlkLTRmNzItODczYS1iMzJhNTlkYjU4MzciLCJleHAiOjE2OTI3MjkzOTQsImFkbWluaXN0cmFkb3IiOmZhbHNlLCJjcGZDbnBqIjoiMzAwMTYyNjQwMDAxMDIiLCJlbWFpbCI6ImNvbnRhdG9AZG9pc3JzaXN0ZW1hcy5jb20uYnIiLCJpZEJhc2VEYWRvcyI6OTQyLCJub21lIjoiRE9JU1IgU0VSVklDT1MgREUgREVTRU5WT0xWSU1FTlRPIEUgQ09OU1VMVE9SSUEgREUgU0lTVEVNQSBMVERBIC8gRG9pc1IgU2lzdGVtYXMifQ.bl60KmmAWQ1Mqp-Z7DUeLCvy7gF_3_3st9u66-dE4Jd5GoeTGT4FF_zhywyHEMsEWXZZNay4YhVONtryvR6imQ`,
//                 'Accept': 'application/json',
               
//             },
//         })

      
        try {
            await axios.post(`${url_apiParams}/v1/orgaos/${cnpjParams}/compras/${anoCompra}/${sequencialcompra}/atas`,formData)
                .then( async res => {

                    
                   await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Cadastrado com Sucesso!',
                        showConfirmButton: false,
                        timer: 1500
                      })

                      console.log(res)


                })
        } catch (error) {

            console.log(error)
            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
              })

        };


    }



    return (
        <div className="form-atos">



            <div className='Dados-Title'>

                <h1>Inserir Atos e Preços</h1>
                <h3> CÂMARA MUNICIPAL DE CIDADE - BAHIA  <img src='' /> </h3>

            </div>

            <hr />

            <div className='form-create'>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>


                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>Numero da Ata Registro de Preco <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="text"
                                onChange={handleNumeroRegistro}
                                value={numeroRegistro}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Ano da Ata <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="number"
                                onChange={handleAnoAta}
                                value={anoAta}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                            <Form.Label>Data de Assinatura <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="date"
                                    required
                                    onChange={handleDataAssinatura}
                                    value={dataAssinatura}
                                />

                            </InputGroup>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>Data Vigencia Iniciada <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="date"
                                    required
                                    onChange={handleDataVigilensianicio}
                                    value={datavigilensianicio}
                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Data Vigencia Finalizada <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="date"
                                    required
                                    onChange={handleDataFim}
                                    value={dataFim}
                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustomUsername"  style={{display:'none'}}>
                            <Form.Label>Ano da contratação <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    required
                                    onChange={handleAnoCompra}
                                    value={anoCompra}
                                />

                            </InputGroup>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01"  style={{display:'none'}}>
                            <Form.Label>Numero Sequencial da Compra <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="text"
                                onChange={handleSequencialCompra}
                                value={sequencialcompra}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom01"  style={{display:'none'}}>
                            <Form.Label>CNPJ <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder='CNPJ'
                                onChange={handleCnpj}
                                value={cnpj}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <Form.Label>Documento <b>*</b></Form.Label>

                            <Form.Control type="file" name='documento' onChange={handleDocumento} required />

                        </Form.Group>
                    </Row>

                    <Button type="submit">Cadastrar</Button>
                </Form>
            </div>

        </div>
    );
}

export default FormularioAtosPreco;
