import { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import './dados-modules.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Swal from 'sweetalert2';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import DadosDelete from './delete';
import DadosDeleteContrato from './deleteContrato';
import DadosDeleteContratoAnexado from './deleteContratoAnexado';



function IdDadosPages() {

    const [list, setListDoc] = useState([""])
    const [listContrato, setListDocContrato] = useState([""])

    const token = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYmE3OTcxOS1lMDlkLTRmNzItODczYS1iMzJhNTlkYjU4MzciLCJleHAiOjE2OTQ2Mzc5ODYsImFkbWluaXN0cmFkb3IiOmZhbHNlLCJjcGZDbnBqIjoiMzAwMTYyNjQwMDAxMDIiLCJlbWFpbCI6ImNvbnRhdG9AZG9pc3JzaXN0ZW1hcy5jb20uYnIiLCJpZEJhc2VEYWRvcyI6OTQyLCJub21lIjoiRE9JU1IgU0VSVklDT1MgREUgREVTRU5WT0xWSU1FTlRPIEUgQ09OU1VMVE9SSUEgREUgU0lTVEVNQSBMVERBIC8gRG9pc1IgU2lzdGVtYXMifQ.a5iS5qx3J6ILUAUtsE4z9aHMJWsxYOPITH86ZPoEIT92iEB0JNDqK2MHwRPcq9fwf1NujEdTY7gO73kfndfOaQ'
    const [cnpjCompra, setCnpjCompra] = useState('');
    const [anoContrato, setAnoContrato] = useState('');
    const [sequencialcontrato, setSequencialContrato] = useState('');
    const [sequencialDocumento, setSequencialDocumento] = useState(['']);

    const [documento, setArquivo] = useState([''])
    const [titulodocumento, setTituloDocumento] = useState([''])
    const location = useNavigate()

    const urlParams = new URLSearchParams(window.location.search)

    const cnpjParams = urlParams.get("cnpj")
    const sequencialParams = urlParams.get("sequencial")
    const anoParams = urlParams.get("ano")
    const atasParams = urlParams.get("atas")
    




    const [anoContratoContrato, setAnoContratoContrato] = useState('');
    const [sequencialcontratoContrato, setSequencialContratoContrato] = useState('');
    const [sequencialDocumentoContrato, setSequencialDocumentoContrato] = useState('');

    const [documentoContrato, setArquivoContrato] = useState([''])
    const [titulodocumentoContrato, setTituloDocumentoContrato] = useState([''])

    const handlearquivo = (e) => {
        setArquivo(e.target.files[0])
    }

    const handleTituloDocumento = (e) => {
        setTituloDocumento(e.target.value)
    }

    const handleAnoContrato = (e) => {
        setAnoContrato(e.target.value)
    }


    const handlearquivoContrato = (e) => {
        setArquivo(e.target.files[0])
    }

    const handleTituloDocumentoContrato = (e) => {
        setTituloDocumento(e.target.value)
    }



    useEffect(() => {

        const LoadPages = async () => {

            try {

                await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjParams}/compras/${anoParams}/${sequencialParams}/arquivos`, {
                    headers: {
                        'Authorization': token

                    },
                })
                    .then(async res => {

                        const listar = res.data;

                        for (let index = 0; index < listar.length; index++) {
                            const element = listar[index];
                            setCnpjCompra(element.cnpj)
                            setListDoc(element.res)
                            setSequencialContrato(element.sequencialCompra)
                            setAnoContrato(element.anoCompra)
                            setSequencialDocumento(element.sequencialDocumento)

                        }

                        console.log(res.data)
                        setListDoc(res.data)




                    })

            } catch (error) {

                console.log(error)

            }


        }

        LoadPages()

    }, [])


    const [idDocumentoeditais, setIddocumentoeditais] = useState([0])

    const handleidDoceditais = (e) =>{

        setIddocumentoeditais(e.target.value)

    }

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        const formData = new FormData();
        formData.append('arquivo', documento)

        const instance = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjParams}/compras`, // Substitua pela URL do seu servidor
            timeout: 10000, // Tempo limite da solicitação
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data',
                'Authorization': token,
                'Accept': '*/*',
                'Titulo-Documento': 'Edital-2021-1',
                'Tipo-Documento-Id': idDocumentoeditais, // Substitua pelo domínio permitido, se possível

            },
        });

        try {
            await instance.post(`/${anoParams}/${sequencialParams}/arquivos`, formData)
                .then(async res => {

                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Documento Adicionado!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    window.location.reload()




                })
        } catch (error) {

            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
            })



        };


    }



    const [idDocumento, setIddocumento] = useState([0])

    const handleidDoc = (e) =>{

        setIddocumento(e.target.value)

    }


    const handleSubmitFormContrato = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        const formData = new FormData();
        formData.append('arquivo', documento)

        const instance = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjParams}/contratos`, // Substitua pela URL do seu servidor
            timeout: 10000, // Tempo limite da solicitação
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data',
                'Authorization': token,
                'Accept': '*/*',
                'Titulo-Documento': 'Contrato',
                'Tipo-Documento-Id': idDocumento, // Substitua pelo domínio permitido, se possível

            },
        });

        try {
            await instance.post(`/${anoParams}/${sequencialParams}/arquivos`, formData)
                .then(async res => {

                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Documento Adicionado!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    window.location.reload()




                })
        } catch (error) {

            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
            })



        };


    }


    


    const [anoCompra, setanoCompra] = useState("")
    const [modalidadeNome, setModalidadeNomea] = useState("")
    const [modoDisputaNome, setModoDisputaNome] = useState("")
    const [numeroControlePNCP, setnumeroControlePNCP] = useState("")
    const [objetoCompra, setobjetoCompra] = useState("")
    const [processo, setprocesso] = useState("")
    const [situacaoCompraNomee, setsituacaoCompraNomee] = useState("")
    const [tipoInstrumentoConvocatorioNome, settipoInstrumentoConvocatorioNome] = useState("")
    const [valorTotalEstimado, setvalorTotalEstimado] = useState("")
    const [amparoLegalNome, setamparoLegalNome] = useState("")
    const [descricao, setdescricao] = useState("")
    const [numeroCompra, setnumeroCompra] = useState("")
    const [usuarioNome, setNomeusuario] = useState("")
    const [orgao, setOrgao] = useState("")
    const [MSGEnviado, setMsgEnviado] = useState("")
    const [MSGEnviadoResult, setMsgEnviadoResult] = useState("")
    const [MSGEnviado2, setMsgEnviado2] = useState("")
    const [MSGEnviadoResult2, setMsgEnviadoResult2] = useState("")
    const [MSGEnviado3, setMsgEnviado3] = useState("")
    const [MSGEnviadoResult3, setMsgEnviadoResult3] = useState("")


    const [verify, setVerify] = useState("")
    const [verify2, setVerify2] = useState("")

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);

    const handleShow2 = async () => {

        const getvalue = localStorage.getItem('valor3')

        if (await verify2 === getvalue) {

            setShow2(true);

        } else {

            await location('/contrato');
            setShow2(false);


        }


    }

    const handleShow3 = async () => {

        const getvalue = localStorage.getItem('valor2')

        if (verify != getvalue) {


            return setMsgEnviado2("Pendente"), setMsgEnviadoResult2("link"), location('/ata/registropreco')


        } else {


            return setMsgEnviado2("Enviado"), setMsgEnviadoResult2("success")


        }




    }



    const handleShow = async () => {

        const getvalue = localStorage.getItem('valor')

        if (await situacaoCompraNomee === getvalue) {

            setShow(true);

        } else {
            location('/inserircompra')
            setShow(false);




        }

    }

    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);


    useEffect(() => {

        const LoadPages = async () => {

            try {

                await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjParams}/compras/${anoParams}/${sequencialParams}`, {
                    headers: {

                        'Authorization': token

                    },
                })
                    .then(async res => {



                        console.log(res)
                        setanoCompra(res.data.anoCompra)
                        setModalidadeNomea(res.data.modalidadeNome)
                        setModoDisputaNome(res.data.modoDisputaNome)
                        setnumeroControlePNCP(res.data.numeroControlePNCP)
                        setsituacaoCompraNomee(res.data.situacaoCompraNome)
                        settipoInstrumentoConvocatorioNome(res.data.tipoInstrumentoConvocatorioNome)
                        setprocesso(res.data.processo)
                        setvalorTotalEstimado(res.data.valorTotalEstimado)
                        setamparoLegalNome(res.data.amparoLegalNome)
                        setdescricao(res.data.descricao)
                        setnumeroCompra(res.data.numeroCompra)
                        setobjetoCompra(res.data.objetoCompra)
                        setNomeusuario(res.data.usuarioNome)
                        setOrgao(res.data.orgaoEntidade.razaoSocial)

                        await localStorage.setItem('valor', res.data.situacaoCompraNome)
                        const getvalue = localStorage.getItem('valor')

                        if (await res.data.situacaoCompraNome === getvalue) {

                            return setMsgEnviado("Enviado"), setMsgEnviadoResult("success")

                        } else {

                            return setMsgEnviado("Pendente"), setMsgEnviadoResult("link")

                        }



                    })

            } catch (error) {

                console.log(error)

            }


        }

        LoadPages()

    }, [])




    // const []


    const [Listacontrato, setListaContrato] = useState([])
    const [Listacontratos, setListaContratos] = useState([])

    useEffect(() => {    

        const LoadPages = async () => {

            try {

                await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjParams}/contratos/${anoParams}/${sequencialParams}`, {
                    headers: {
                        'Authorization': token

                    },
                })
                    .then(async res => {

                        setVerify2(res.data.tipoPessoa)
                        setListaContrato([res.data])

                        await localStorage.setItem('valor3', res.data.tipoPessoa)
                        const getvalue = await localStorage.getItem('valor3')

                        if (await res.data.tipoPessoa === getvalue) {

                            return setMsgEnviado3("Enviado"), setMsgEnviadoResult3("success")

                        } else {

                            return setMsgEnviado3("Pendente"), setMsgEnviadoResult3("link")

                        }

                    })


            } catch (error) {

                console.log(error)

            }


        }

        LoadPages()

    }, [])

    console.log(MSGEnviado3)

    useEffect(() => {

        const LoadPages = async () => {

            try {

                await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjParams}/contratos/${anoParams}/${sequencialParams}/arquivos`, {
                    headers: {
                        'Authorization': token

                    },
                })
                    .then(async res => {

                        const listar = res.data;

                        for (let index = 0; index < listar.length; index++) {
                            const element = listar[index];
                            setCnpjCompra(element.cnpj)
                            setSequencialContratoContrato(element.sequencialCompra)
                            setSequencialDocumentoContrato(element.sequencialDocumento)
                            setAnoContratoContrato(element.anoCompra)
                            setListaContratos(element.sequencialCompra)


                        }

                        setListDocContrato(res.data)






                    })

            } catch (error) {

                console.log(error)

            }


        }

        LoadPages()

    }, [])






    useEffect(() => {

        const LoadPages = async () => {

            try {

                await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjParams}/compras/${anoParams}/${sequencialParams}/atas/${atasParams}`, {
                    headers: {

                        'Authorization': token

                    },
                })
                    .then(async res => {



                        setListDocContrato(res.data)
                        setVerify(res.data.ata.numeroAtaRegistroPreco)
                        console.log(verify)

                        await localStorage.setItem('valor2', res.data.ata.numeroAtaRegistroPreco)
                        const getvalue2 = localStorage.getItem('valor2')


                        if (await verify != getvalue2) {


                            return setMsgEnviado2("Pendente"), setMsgEnviadoResult2("link")

                        } else {

                            return setMsgEnviado2("Enviado"), setMsgEnviadoResult2("success")



                        }

                    })


            } catch (error) {

                console.log(error)

            }


        }

        LoadPages()

    }, [])



    const SubmitDeleteContratoform = async () => {

        const instance = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjParams}`, // Substitua pela URL do seu servidor
            timeout: 10000, // Tempo limite da solicitação
            headers: {

                'Authorization': token,
                'Accept': '*/*',

            },
        })

        try {
            await instance.delete(`/contratos/${anoContratoContrato}/${sequencialcontratoContrato}/arquivos/${sequencialDocumentoContrato}`, {


            })
                .then(async res => {

                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Excluido com Sucesso!',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    window.location.reload()


                })
        } catch (error) {

            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
            })



        };


    }







    return (
        <div className="IdDados">

            <h1>Mapa de Publicação</h1>


            <hr />


            <div className='dados-get'>



                <div className='list-dados'>
                    <Button variant="primary" className='back'><b>&lt;</b></Button>{' '}

                    <div className='list-nome'>
                        <h4>Licitação n°: {numeroCompra}</h4>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Ano:</div>
                                    {anoCompra}
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>

                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Orgão:</div>
                                    {orgao}
                                </div>
                            </ListGroup.Item>

                            <ListGroup.Item>

                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Modalidade:</div>
                                    {modalidadeNome}
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>

                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Modo Dispulta:</div>
                                    {modoDisputaNome}
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>

                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Modalidade:</div>
                                    {modalidadeNome}
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>

                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Objeto:</div>
                                    {objetoCompra}

                                </div>
                            </ListGroup.Item>

                        </ListGroup>

                        <div className='list-button'>
                            <Button variant="primary">Registros</Button>{' '}
                        </div>


                    </div>



                    <div className='detalhes'>

                        <ListGroup variant="flush">
                            <ListGroup.Item><b>Nome usuario</b>: {usuarioNome}</ListGroup.Item>
                            <ListGroup.Item><b>numeroControlePNCP</b>: {numeroControlePNCP}</ListGroup.Item>
                            <ListGroup.Item><b>N° do Processo</b>: {processo}</ListGroup.Item>
                            <ListGroup.Item><b>Valor Estimado</b>: {valorTotalEstimado}</ListGroup.Item>
                            <ListGroup.Item><b>Intrumento Convocatorio</b>: {tipoInstrumentoConvocatorioNome}</ListGroup.Item>
                        </ListGroup>


                    </div>

                </div>


                <div className='envios-form'>

                    <Toast>
                        <Toast.Header>

                            <strong className="me-auto"><h6>Envios de arquivos - <b>Fases PNCP</b></h6></strong>
                        </Toast.Header>
                        <Toast.Body>

                            <b className='btn-b' onClick={handleShow}><p>Editais e Avisos de Contratação PNCP</p></b>
                            <b className='btn-b' onClick={handleShow2}><p>Contrato PNCP</p></b>
                            <b><p>Ata de Registro PNCP</p> <Button onClick={handleShow3} variant={MSGEnviadoResult2}>{MSGEnviado2} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-square-fill" viewBox="0 0 16 16">
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                </svg></Button>{' '}</b>

                        </Toast.Body>
                    </Toast>

                    <Modal
                        size="lg"
                        centered
                        show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <p>PNCP / <b>Avisos e Editais</b></p>
                            <Modal.Title>
                                <Button variant="success" >Enviado para PNCP <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-square-fill" viewBox="0 0 16 16">
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                </svg> </Button>{' '}  Avisos / Editais PNCP
                            </Modal.Title>
                            <b>Envie e gerencie arquivos para esta fase da licitação por aqui</b>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>Documento</h4>
                            <hr />
                            <Form noValidate validated={validated} onSubmit={handleSubmitForm}>
                                <Row className="mb-5">

                                    <Form.Group as={Col} md="5" controlId="validationCustom04">
                                        <Form.Label>Documento <b>*</b></Form.Label>

                                        <Form.Control type="file" onChange={handlearquivo} name='documento' required />

                                    </Form.Group>
                                    <Form.Group as={Col} md="5" controlId="validationCustom01">
                                        <Form.Label>Tipo de Documento <b>*</b></Form.Label>
                                        <Form.Select required aria-label="Default select example" onChange={handleidDoceditais} >
                                            <option selected value={""}>Selecione</option>
                                            <option value={1}>Aviso de Contratação Direta</option>
                                            <option value={2}>Edital</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Button className='add-doc' type="submit">Adicionar</Button>
                            </Form>


                            <hr></hr>
                            <h4>Edital Anexados</h4>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th className='doc'>Documento</th>
                                        <th className='tipo'>Tipo</th>
                                        <th className='url-aquivo'>Link PNCP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.length > 0 ? (

                                            list.map((listdocs) => (

                                                <DadosDelete 
                                                id={listdocs.sequencialDocumento}
                                                url={listdocs.url}
                                                tipoDocumentoNome={listdocs.tipoDocumentoNome}
                                                anoContrato={listdocs.anoCompra}
                                                cnpjCompra={listdocs.cnpj}
                                                sequencialcontrato={listdocs.sequencialCompra}


                                                />

                                                
                                            ))) : (
                                            <p>Não há itens na lista!</p>
                                        )}

                                </tbody>
                            </Table>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Voltar
                            </Button>
                        </Modal.Footer>
                    </Modal>



                    {/*  */}

                    <Modal
                        size="lg"
                        centered
                        show={show2} onHide={handleClose2}>
                        <Modal.Header closeButton>
                            <p>PNCP / <b>Contrato</b></p>
                            <Modal.Title>
                                <Button variant="success" >Enviado para PNCP <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-square-fill" viewBox="0 0 16 16">
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                </svg> </Button>{' '}
                                Avisos / Editais PNCP
                            </Modal.Title>
                            <b>Envie e gerencie arquivos para esta fase da licitação por aqui</b>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>Documento</h4>
                            <hr />
                            <Form noValidate validated={validated2} onSubmit={handleSubmitFormContrato}>
                                <Row className="mb-5">

                                    <Form.Group as={Col} md="2" controlId="validationCustom01">
                                        <Form.Label>Id Contrato <b>*</b></Form.Label>
                                        <Form.Select required aria-label="Default select example" >
                                            <option selected value={""}>Selecione</option>

                                            <option value={Listacontratos}>{Listacontratos}</option>

                                            
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>Documento <b>*</b></Form.Label>

                                        <Form.Control type="file" onChange={handlearquivoContrato} name='documento' required />

                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Form.Label>Tipo de Documento <b>*</b></Form.Label>
                                        <Form.Select required aria-label="Default select example" onChange={handleidDoc}>
                                            <option selected value={""}>Selecione</option>
                                            <option value={12}>Contrato</option>
                                            <option value={13}>Termo de Rescisão</option>
                                            <option value={14}>Termo Aditivo</option>
                                            <option value={15}>Termo de Apostilamento</option>
                                            <option value={16}>Outros</option>
                                            <option value={17}>Nota de Empenho</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                                        <Form.Label>Ano <b>*</b></Form.Label>

                                        <Form.Control type="number" name='ano' onChange={handleAnoContrato} value={anoContrato} required />

                                    </Form.Group>
                                </Row>
                                <Button className='add-doc' type="submit">Adicionar</Button>
                            </Form>


                            <hr></hr>
                            <h4>Documentos</h4>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th className='doc'>Id Contrato</th>
                                        <th className='tipo'>Tipo </th>
                                        <th className='url-aquivo'>Link PNCP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listContrato.length > 0 ? (

                                            listContrato.map((listdocs, itemIndex) => (

                                                <DadosDeleteContrato 
                                                id={listdocs.sequencialDocumento}
                                                url={listdocs.url}
                                                tipoDocumentoNome={listdocs.tipoDocumentoNome}
                                                anoContratoContrato={listdocs.anoCompra}
                                                cnpjCompra={listdocs.cnpj}
                                                sequencialcontratoContrato={listdocs.sequencialCompra} />

                                            ))) : (
                                            <p>Não há itens na lista!</p>
                                        )}


                                </tbody>
                            </Table>

                            <hr></hr>
                            <h4>Contratos Anexados <Link to={`/contrato`}><Button variant="primary">Novo Contrato</Button>{' '}</Link></h4>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th className='doc'>Id Contrato</th>
                                        <th className='tipo'>Tipo </th>
                                        <th className='url-aquivo'>Link PNCP</th>
                                        <th className='tipo'>Fiscal Contrato</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {Listacontrato.map((listdocs) => (  
                                    <DadosDeleteContratoAnexado 
                                    
                                    urlCipi={listdocs.urlCipi}
                                    tipoContratoNome={listdocs.tipoContrato.nome}
                                    anoContrato={listdocs.anoContrato}
                                    cnpjCompra={listdocs.orgaoEntidade.cnpj}
                                    sequencialcontrato={listdocs.sequencialContrato} 
                                    tipoPessoa={listdocs.tipoPessoa}
                                    />
                                    
                                    ))}

                                </tbody>
                            </Table>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose2}>
                                Voltar
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>





            </div>


        </div>
    );
}

export default IdDadosPages;
