import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Routes, Route, Navigate, useParams } from "react-router-dom";

import FormAtaPreco from '../AtoPreco/atros'
import FormAtaPrecoCancelamento from '../AtoPreco/atrosCancelamento'
import FormAtaPrecoUpdate from '../AtoPreco/atrosUpdate'
import FormAtaPrecoDelete from '../AtoPreco/atrosDelete'


import FormCompra from '../InserirCompra/Form'
import FormCompraDelete from '../InserirCompra/Compradelete'
import FormCompraUpdate from '../InserirCompra/compraUpdate'


import FormContrato from '../Contrato/contrato'
import FormContratoDelete from '../Contrato/contratoDelete'
import FormContratoUpdate from '../Contrato/contratoEdite'


import DadosId from '../dadosId/IdUser'


function RoutesPages() {


    return (
        <div className="routes">

            <Routes>

                <Route path="/inserircompra" element={<FormCompra/>} />
                <Route path="/editarcompra" element={<FormCompraUpdate />} />
                <Route path="/excluircompra" element={<FormCompraDelete />} />


                <Route path="/ata/registropreco" element={<FormAtaPreco/>} />
                <Route path="/ata/registropreco/cancelamento" element={<FormAtaPrecoCancelamento/>} />
                <Route path="/ata/registropreco/editar" element={<FormAtaPrecoUpdate/>} />
                <Route path="/ata/registropreco/delete" element={<FormAtaPrecoDelete/>} />


                <Route path="/contrato" element={<FormContrato/>} />
                <Route path="/contratoeditar" element={<FormContratoUpdate/>} />
                <Route path="/contratodelete" element={<FormContratoDelete/>} />


                <Route path={`/dadosid`} element={<DadosId/>} />

            </Routes>



        </div>
    );
}

export default RoutesPages;
