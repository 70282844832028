import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import RoutesPages from './components/router/routes';



function App() {
  return (
    <div className="App">

      <RoutesPages/>
      
    </div>
  );
}

export default App;
