import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import '../form-modules.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'


function FormularioAtosPrecoCancelamento() {

    const [validated, setValidated] = useState(false);
    const [numeroRegistro, setNumeroregistro] = useState('');
    const [anoAta, setAnoAta] = useState('');
    const [dataAssinatura, setDataAssinatura] = useState('');
    const [datavigilensianicio, setDataVigilesianicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [anoCompra, setAnoCompra] = useState('');
    const [sequencialcompra, setSequencialCompra] = useState('');
    const [sequencialAta, setSequencialAta] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [justificativa, setJustificativa] = useState('');
    const [cancelado, setCancelado] = useState('');
    const [canceladoData, setDataCancelado] = useState('');


    const handleNumeroRegistro = (e) => {
        setNumeroregistro(e.target.value)
    }

    const handleCancelado = (e) => {
        setCancelado(e.target.value)
    }

    const handleDataCancelado = (e) => {
        setDataCancelado(e.target.value)
    }

    const handleJustificativa = (e) => {
        setJustificativa(e.target.value)
    }

    const handleAnoAta = (e) => {
        setAnoAta(e.target.value)
    }

    const handleDataAssinatura = (e) => {
        setDataAssinatura(e.target.value)
    }

    const handleCnpj = (e) => {
        setCnpj(e.target.value)
    }

    const handleDataVigilensianicio = (e) => {
        setDataVigilesianicio(e.target.value)
    }

    const handleDataFim = (e) => {
        setDataFim(e.target.value)
    }

    const handleAnoCompra = (e) => {
        setAnoCompra(e.target.value)
    }

    const handleSequencialCompra = (e) => {
        setSequencialCompra(e.target.value)
    }

    const handleSequencialAta = (e) => {
        setSequencialAta(e.target.value)
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);


        const instance = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpj}`, // Substitua pela URL do seu servidor
            timeout: 10000, // Tempo limite da solicitação
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYmE3OTcxOS1lMDlkLTRmNzItODczYS1iMzJhNTlkYjU4MzciLCJleHAiOjE2OTI3OTU4NjYsImFkbWluaXN0cmFkb3IiOmZhbHNlLCJjcGZDbnBqIjoiMzAwMTYyNjQwMDAxMDIiLCJlbWFpbCI6ImNvbnRhdG9AZG9pc3JzaXN0ZW1hcy5jb20uYnIiLCJpZEJhc2VEYWRvcyI6OTQyLCJub21lIjoiRE9JU1IgU0VSVklDT1MgREUgREVTRU5WT0xWSU1FTlRPIEUgQ09OU1VMVE9SSUEgREUgU0lTVEVNQSBMVERBIC8gRG9pc1IgU2lzdGVtYXMifQ.YQID21EmE4gPP2v6esxdR_Mpo1vOrUFFbtJMoiNue6Z6upoM6UaYvSpMOWRAD-ADljo7wVIk_bDQd53TTsZCHg`,
                'Accept': 'application/json',
               
            },
        })

       
        try {
            await instance.put(`/compras/${anoCompra}/${sequencialcompra}/atas/${sequencialAta}`, {

                numeroAtaRegistroPreco: numeroRegistro,
                anoAta: anoAta,
                dataAssinatura: dataAssinatura,
                dataVigenciaInicio: datavigilensianicio,
                dataVigenciaFim: dataFim,
                justificativa: justificativa,
                cancelado : true,
                dataCancelamento: `${canceladoData}:00`

            })
                .then( async res => {

                    
                   await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Cancelamento feito com Sucesso!',
                        showConfirmButton: false,
                        timer: 1500
                      })

                      console.log(res)


                })
        } catch (error) {

            console.log(error)
            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
              })

        };


    }



    return (
        <div className="form-atos">



            <div className='Dados-Title'>

                <h1>Inserir Atos e Preços</h1>
                <h3> CÂMARA MUNICIPAL DE CIDADE - BAHIA  <img src='' /> </h3>

            </div>

            <hr />

            <div className='form-create'>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>


                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>Numero da Ata Registro de Preco <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="1/2023"
                                onChange={handleNumeroRegistro}
                                value={numeroRegistro}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Ano da Ata <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="number"
                                onChange={handleAnoAta}
                                value={anoAta}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                            <Form.Label>Data de Assinatura <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="date"
                                    required
                                    onChange={handleDataAssinatura}
                                    value={dataAssinatura}
                                />

                            </InputGroup>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>Data Vigencia Iniciada <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="date"
                                    required
                                    onChange={handleDataVigilensianicio}
                                    value={datavigilensianicio}
                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Data Vigencia Finalizada <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="date"
                                    required
                                    onChange={handleDataFim}
                                    value={dataFim}
                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                            <Form.Label>Ano da contratação <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    required
                                    onChange={handleAnoCompra}
                                    value={anoCompra}
                                />

                            </InputGroup>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>Sequencial da Compra gerado PNCP <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="number"
                                onChange={handleSequencialCompra}
                                value={sequencialcompra}
                                placeholder="gerado PNCP na hora da inclusão"

                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>gerado PNCP Sequencial da Ata <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="gerado PNCP na hora da inclusão"
                                onChange={handleSequencialAta}
                                value={sequencialAta}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>CNPJ <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder='CNPJ'
                                onChange={handleCnpj}
                                value={cnpj}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Data de Cancelamento <b>*</b></Form.Label>
                            <Form.Control type="datetime-local" required onChange={handleDataCancelado} value={canceladoData} />

                        </Form.Group>


                    </Row>

                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Justificativa</Form.Label>
                        <Form.Control type="text" className='options-text' rows={3} onChange={handleJustificativa} value={justificativa} required />

                    </Form.Group>

                    <Button type="submit">Cadastrar</Button>
                </Form>
            </div>

        </div>
    );
}

export default FormularioAtosPrecoCancelamento;
