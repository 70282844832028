import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import '../form-modules.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'


function FormularioContratoEdite() {

    const [validated, setValidated] = useState(false);

    const [cnpjCompra, setCnpjCompra] = useState('');
    const [anoContrato, setAnoContrato] = useState('');
    const [sequencialcontrato, setSequencialContrato] = useState('');
    const [justificativa, setJustificativa] = useState('');



    const handleJustificativa = (e) => {
        setJustificativa(e.target.value)
    }

    
    const handleSequencialContrato = (e) => {
        setSequencialContrato(e.target.value)
    }


    const handleAnoContrato = (e) => {
        setAnoContrato(e.target.value)
    }


    const handleCnpj = (e) => {
        setCnpjCompra(e.target.value)
    }

    // new dados
    const [anoAta, setAnoAta] = useState('');
    const [datavigilensianicio, setDataVigilesianicio] = useState('');


    




    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);


        const instance = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjCompra}`, // Substitua pela URL do seu servidor
            timeout: 10000, // Tempo limite da solicitação
            headers: {

                'Authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYmE3OTcxOS1lMDlkLTRmNzItODczYS1iMzJhNTlkYjU4MzciLCJleHAiOjE2OTI0NTI4NTAsImFkbWluaXN0cmFkb3IiOmZhbHNlLCJjcGZDbnBqIjoiMzAwMTYyNjQwMDAxMDIiLCJlbWFpbCI6ImNvbnRhdG9AZG9pc3JzaXN0ZW1hcy5jb20uYnIiLCJpZEJhc2VEYWRvcyI6OTQyLCJub21lIjoiRE9JU1IgU0VSVklDT1MgREUgREVTRU5WT0xWSU1FTlRPIEUgQ09OU1VMVE9SSUEgREUgU0lTVEVNQSBMVERBIC8gRG9pc1IgU2lzdGVtYXMifQ.X2wp5YrvZ0GWyfVatzWdaLGCkP6bJd75OgMA_r30I3nniS8QzBwbQGYiggT_jI8PPl6Y0O1EnlYFK1xS8nXcmw`,
                'Accept': '*/*',

            },
        })

        try {
            await instance.delete(`/contratos/${anoContrato}/${sequencialcontrato}`, {


                justificativa:justificativa


            })
                .then(async res => {

                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Excluido com Sucesso!',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    console.log(res)

                })
        } catch (error) {

            console.log(error)

            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
            })



        };


    }



    return (
        <div className="form-contrato">



            <div className='Dados-Title'>

                <h1>Inserir Contrato</h1>
                <h3> CÂMARA MUNICIPAL DE CIDADE - BAHIA  <img src='' /> </h3>

            </div>

            <hr />

            <div className='form-create'>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>


                    <Row className="mb-3">



                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Ano de Contrato <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    placeholder="2023"
                                    required
                                    onChange={handleAnoContrato}
                                    value={anoContrato}

                                />
                            </InputGroup>
                        </Form.Group>





                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Sequencial Contrato PNCP <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="gerado PNCP na hora da inclusão"
                                onChange={handleSequencialContrato}
                                value={sequencialcontrato}
                            />
                        </Form.Group>


                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>CNPJ <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    onChange={handleCnpj}
                                    value={cnpjCompra}

                                />

                            </InputGroup>
                        </Form.Group>


                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Justificativa <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    onChange={handleJustificativa}
                                    value={justificativa}

                                />

                            </InputGroup>
                        </Form.Group>



                    </Row>










                    <Button type="submit">Cadastrar</Button>
                </Form>
            </div>

        </div>
    );
}

export default FormularioContratoEdite;
