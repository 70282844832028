import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import '../form-modules.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'


function FormularioContratoEdite() {

    const [validated, setValidated] = useState(false);

    const [numeroRegistro, setNumeroregistro] = useState('');
    const [cnpjCompra, setCnpjCompra] = useState('');
    const [anoCompra, setAnoCompra] = useState('');
    const [sequencialcompra, setSequencialCompra] = useState('');
    const [sequencialcontrato, setSequencialContrato] = useState('');
    const [tipoContrato, setTipoContrato] = useState('');
    const [numeroContratoEmpenho, setNumeroContratoEmpenho] = useState('');
    const [anoContrato, setAnoContrato] = useState('');
    const [processo, setProcesso] = useState('');
    const [categoriaProcessoId, setCategoriaProcessoId] = useState('');
    const [receita, setReceita] = useState('');
    const [codigoUnidade, setCodigoUnidade] = useState('');
    const [niFornecedor, setNiFornecedor] = useState('');
    const [tipoPessoaFornecedor, setTipoPessoaFornecedor] = useState('');
    const [nomeRazaoSocialFornecedor, setNomeRazaoSocialFornecedor] = useState('');
    const [niFornecedorSubContratado, setNiFornecedorSubContratado] = useState('');
    const [tipoPessoaFornecedorSubContratado, setTipoPessoaFornecedorSubContratado] = useState('');
    const [nomeRazaoSocialFornecedorSubContratado, setNomeRazaoSocialFornecedorSubContratado] = useState('');
    const [objetoContrato, setObjetoContrato] = useState('');
    const [informacaoComplementar, setInformacaoComplementar] = useState('');
    const [valorInicial, setValorInicial] = useState('');
    const [numeroParcelas, setNumeroParcelas] = useState('');
    const [valorParcela, setValorParcela] = useState('');
    const [valorGlobal, setValorGlobal] = useState('');
    const [valorAcumulado, setValorAcumulado] = useState('');
    const [dataAssinatura, setDataAssinatura] = useState('');
    const [dataVigenciaInicio, setDataVigenciaInicio] = useState('');
    const [dataVigenciaFim, setDataVigenciaFim] = useState('');
    const [identificadorCipi, setIdentificadorCipi] = useState('');
    const [urlCipi, setUrlCipi] = useState('');



    const handleUrlCipi = (e) => {
        setUrlCipi(e.target.value)
    }



    const handleIdentificadorCipi = (e) => {
        setIdentificadorCipi(e.target.value)
    }


    const handleDataVigenciaFim = (e) => {
        setDataVigenciaFim(e.target.value)
    }


    const handleDataVigenciaInicio = (e) => {
        setDataVigenciaInicio(e.target.value)
    }

    const handleValorAcumulado = (e) => {
        setValorAcumulado(e.target.value)
    }


    const handleValorGlobal = (e) => {
        setValorGlobal(e.target.value)
    }


    const handleValorParcela = (e) => {
        setValorParcela(e.target.value)
    }


    const handleNumeroParcelas = (e) => {
        setNumeroParcelas(e.target.value)
    }


    const handleValorInicial = (e) => {
        setValorInicial(e.target.value)
    }


    const handleInformacaoComplementar = (e) => {
        setInformacaoComplementar(e.target.value)
    }


    const handleObjetoContrato = (e) => {
        setObjetoContrato(e.target.value)
    }


    const handleNomeRazaoSocialFornecedorSubContratadoo = (e) => {
        setNomeRazaoSocialFornecedorSubContratado(e.target.value)
    }


    const handleNiFornecedorSubContratado = (e) => {
        setNiFornecedorSubContratado(e.target.value)
    }


    const handleNomeRazaoSocial = (e) => {
        setNomeRazaoSocialFornecedor(e.target.value)
    }

    const handleTipoPessoaFornecedorSubContratado = (e) => {
        setTipoPessoaFornecedorSubContratado(e.target.value)
    }

    const handleTipoPessoalFornecedora = (e) => {
        setTipoPessoaFornecedor(e.target.value)
    }


    const handleNiFornecedor = (e) => {
        setNiFornecedor(e.target.value)
    }



    const handleCadigoUnidade = (e) => {
        setCodigoUnidade(e.target.value)
    }


    const handleReceita = (e) => {
        setReceita(e.target.value)
    }

    const handleCatehoriaProcesso = (e) => {
        setCategoriaProcessoId(e.target.value)
    }

    const handleProcesso = (e) => {
        setProcesso(e.target.value)
    }

    const handleAnoContrato = (e) => {
        setAnoContrato(e.target.value)
    }

    const handleNumeroContrato = (e) => {
        setNumeroContratoEmpenho(e.target.value)
    }

    const handleTipoContrato = (e) => {
        setTipoContrato(e.target.value)
    }

    const handleCnpj = (e) => {
        setCnpjCompra(e.target.value)
    }

    // new dados
    const [anoAta, setAnoAta] = useState('');
    const [datavigilensianicio, setDataVigilesianicio] = useState('');


    const handleNumeroRegistro = (e) => {
        setNumeroregistro(e.target.value)
    }

    const handleAnoAta = (e) => {
        setAnoAta(e.target.value)
    }

    const handleDataAssinatura = (e) => {
        setDataAssinatura(e.target.value)
    }



    const handleAnoCompra = (e) => {
        setAnoCompra(e.target.value)
    }

    const handleSequencialCompra = (e) => {
        setSequencialCompra(e.target.value)
    }

    const handleSequencialContrato = (e) => {
        setSequencialContrato(e.target.value)
    }




    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);


        const instance = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjCompra}`, // Substitua pela URL do seu servidor
            timeout: 10000, // Tempo limite da solicitação
            headers: {

                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYmE3OTcxOS1lMDlkLTRmNzItODczYS1iMzJhNTlkYjU4MzciLCJleHAiOjE2OTI0NTI4NTAsImFkbWluaXN0cmFkb3IiOmZhbHNlLCJjcGZDbnBqIjoiMzAwMTYyNjQwMDAxMDIiLCJlbWFpbCI6ImNvbnRhdG9AZG9pc3JzaXN0ZW1hcy5jb20uYnIiLCJpZEJhc2VEYWRvcyI6OTQyLCJub21lIjoiRE9JU1IgU0VSVklDT1MgREUgREVTRU5WT0xWSU1FTlRPIEUgQ09OU1VMVE9SSUEgREUgU0lTVEVNQSBMVERBIC8gRG9pc1IgU2lzdGVtYXMifQ.X2wp5YrvZ0GWyfVatzWdaLGCkP6bJd75OgMA_r30I3nniS8QzBwbQGYiggT_jI8PPl6Y0O1EnlYFK1xS8nXcmw`,
                'Accept': 'application/json',

            },
        })

        try {
            await instance.put(`/contratos/${anoContrato}/${sequencialcontrato}`, {


                cnpjCompra: cnpjCompra,
                anoCompra: anoCompra,
                sequencialCompra: sequencialcompra,
                tipoContratoId: tipoContrato,
                numeroContratoEmpenho: numeroContratoEmpenho,
                processo: processo,
                categoriaProcessoId: categoriaProcessoId,
                receita: receita,
                codigoUnidade: codigoUnidade,
                niFornecedor: niFornecedor,
                tipoPessoaFornecedor: tipoPessoaFornecedor,
                nomeRazaoSocialFornecedor: nomeRazaoSocialFornecedor,
                niFornecedorSubContratado: niFornecedorSubContratado,
                tipoPessoaFornecedorSubContratado: tipoPessoaFornecedorSubContratado,
                nomeRazaoSocialFornecedorSubContratado: nomeRazaoSocialFornecedorSubContratado,
                objetoContrato: objetoContrato,
                informacaoComplementar: informacaoComplementar,
                valorInicial: valorInicial,
                numeroParcelas: numeroParcelas,
                valorParcela: valorParcela,
                valorGlobal: valorGlobal,
                valorAcumulado: valorAcumulado,
                dataAssinatura: dataAssinatura,
                dataVigenciaInicio: dataVigenciaInicio,
                dataVigenciaFim: dataVigenciaFim,
                identificadorCipi: identificadorCipi,
                urlCipi: urlCipi


            })
                .then(async  res => {
                   
                   await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Cadastrado com Sucesso!',
                        showConfirmButton: false,
                        timer: 1500
                      })

                      console.log(res)

                })
        } catch (error) {

           console.log(error)

           await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
              })



        };


    }



    return (
        <div className="form-contrato">



            <div className='Dados-Title'>

                <h1>Editar Contrato</h1>
                <h3> CÂMARA MUNICIPAL DE CIDADE - BAHIA  <img src='' /> </h3>

            </div>

            <hr />

            <div className='form-create'>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>


                    <Row className="mb-3">

                        

                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Ano de Compra <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    placeholder="2023"
                                    required
                                    onChange={handleAnoCompra}
                                    value={anoCompra}
                                />

                            </InputGroup>
                        </Form.Group>

                        

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Sequencial Compra PNCP <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="gerado PNCP na hora da inclusão"
                                onChange={handleSequencialCompra}
                                value={sequencialcompra}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Sequencial Contrato PNCP <b>*</b></Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="gerado PNCP na hora da inclusão"
                                onChange={handleSequencialContrato}
                                value={sequencialcontrato}
                            />
                        </Form.Group>


                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>CNPJ <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    onChange={handleCnpj}
                                    value={cnpjCompra}

                                />

                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Tipo de Contrato <b>*</b></Form.Label>
                            <Form.Select required aria-label="Default select example" onChange={handleTipoContrato} value={tipoContrato}>
                                <option selected value={""}>Selecione</option>
                                <option value="1">  Contrato (termo inicial)</option>
                                <option value="2"> Comodato</option>
                                <option value="3">  Arrendamento</option>
                                <option value="4">  Concessão</option>
                                <option value="5"> Termo de Adesão</option>
                                <option value="6"> Convênio</option>
                                <option value="7">  Empenho</option>
                                <option value="8"> Outros</option>
                                <option value="9">  Termo de Execução Descentralizada (TED)</option>
                                <option value="10"> Acordo de Cooperação Técnica (ACT)</option>
                                <option value="11">  Termo de Compromisso</option>
                                <option value="12">  Carta Contrato</option>
                            </Form.Select>
                        </Form.Group>


                    </Row>

                    <Row className="mb-3">
                    


                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Numero de Empenho <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    required
                                    onChange={handleNumeroContrato}
                                    value={numeroContratoEmpenho}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Ano de Contrato <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    placeholder="2023"
                                    required
                                    onChange={handleAnoContrato}
                                    value={anoContrato}

                                />

                            </InputGroup>
                        </Form.Group>

                         <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Proceso <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder='1/2023'
                                    onChange={handleProcesso}
                                    value={processo}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Categoria de Processo <b>*</b></Form.Label>
                            <Form.Select required aria-label="Default select example" onChange={handleCatehoriaProcesso} value={categoriaProcessoId}>
                                <option selected value={""}>Selecione</option>
                                <option value="1">  Cessão</option>
                                <option value="2">  Compras</option>
                                <option value="3">  Informática (TIC)</option>
                                <option value="4">  Internacional</option>
                                <option value="5">  Locação Imóveis</option>
                                <option value="6">  Mão de Obra</option>
                                <option value="7">  Obras</option>
                                <option value="8">  Serviços</option>
                                <option value="9">  Serviços de Engenharia</option>
                                <option value="10"> Serviços de Saúde</option>
                                <option value="11"> Alienação de bens móveis/imóveis</option>
                            </Form.Select>
                        </Form.Group>




                    </Row>


                    <Row className="mb-3">

                    <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Receita <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                            <Form.Select required aria-label="Default select example" 
                             onChange={handleReceita}
                             value={receita}>
                                <option selected value={""}>Selecione</option>
                                <option value={true}>SIM</option>
                                <option value={false}>NÃO</option>
                            </Form.Select>
                            </InputGroup>

                           

                        </Form.Group>



                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Codigo da Unidade <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder='Digite o Codigo da Unidade!'
                                    onChange={handleCadigoUnidade}
                                    value={codigoUnidade}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Digite o CNPJ/CPF do Fornecedor <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder='fornecedor/arrematante'
                                    onChange={handleNiFornecedor}
                                    value={niFornecedor}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Tipo de Pessoa Fornecedor <b>*</b></Form.Label>
                            
                                <Form.Select required aria-label="Default select example"
                                    onChange={handleTipoPessoalFornecedora}
                                    value={tipoPessoaFornecedor}>
                                    <option selected value={""}>Selecione</option>
                                    <option value="PJ">Pessoa jurídica</option>
                                    <option value="PF">Pessoa física</option>
                                    <option value="PE">Pessoa estrangeira</option>

                                </Form.Select>

                        </Form.Group>

                        
                    </Row>


                    <Row className="mb-3">

                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Nome da Razao Social do Fornecedor</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder='fornecedor/arrematante'
                                    onChange={handleNomeRazaoSocial}
                                    value={nomeRazaoSocialFornecedor}

                                />

                            </InputGroup>
                        </Form.Group>

                       
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Número fornecedor Sub-Contratado </Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder=' CNPJ,CPF'
                                    onChange={handleNiFornecedorSubContratado}
                                    value={niFornecedorSubContratado}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Pessoa Fornecedor SubContratado </Form.Label>
                            <Form.Select required aria-label="Default select example"
                                    onChange={handleTipoPessoaFornecedorSubContratado}
                                    value={tipoPessoaFornecedorSubContratado}>
                                    <option selected value={""}>Selecione</option>
                                    <option value="PJ">Pessoa jurídica</option>
                                    <option value="PF">Pessoa física</option>
                                    <option value="PE">Pessoa estrangeira</option>

                                </Form.Select>
                        </Form.Group>

                               

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Razao Social Fornecedor Contratado </Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    onChange={handleNomeRazaoSocialFornecedorSubContratadoo}
                                    value={nomeRazaoSocialFornecedorSubContratado}
                                    placeholder='Somente caso de subcontratação'

                                />

                            </InputGroup>
                        </Form.Group>
                        
                    </Row>

                    <Row className="mb-3">

                    <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Objeto Contrato <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder='Descrição do objeto do contrato'
                                    onChange={handleObjetoContrato}
                                    value={objetoContrato}

                                />

                            </InputGroup>
                        </Form.Group>


                    <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Informacao Complementar</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Informações complementares"
                                    required
                                    onChange={handleInformacaoComplementar}
                                    value={informacaoComplementar}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Valor Inicial <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    required
                                    placeholder='Valor inicial.  Ex: 100.0000'
                                    onChange={handleValorInicial}
                                    value={valorInicial}
                                    minLength={4}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Numero Parcelas <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    required
                                    placeholder='Número de parcelas'
                                    onChange={handleNumeroParcelas}
                                    value={numeroParcelas}

                                />

                            </InputGroup>
                        </Form.Group>
                        
                    </Row>

                    <Row className="mb-3">

                    <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Valor de Parcela </Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    placeholder="Valor da parcela. Ex: 100.0000"
                                    required
                                    onChange={handleValorParcela}
                                    value={valorParcela}
                                    minLength={4}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Valor Global <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    required
                                    placeholder='Valor global. Ex: 100.0000'
                                    onChange={handleValorGlobal}
                                    value={valorGlobal}
                                    minLength={4}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Valor Acumulado </Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    required
                                    placeholder='Valor acumulado. Ex: 100.0000'
                                    onChange={handleValorAcumulado}
                                    value={valorAcumulado}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Assinatura do contrato <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="date"
                                    placeholder="Data de assinatura do contrato"
                                    required
                                    onChange={handleDataAssinatura}
                                    value={dataAssinatura}

                                />

                            </InputGroup>
                        </Form.Group>

                       
                    </Row>


                    <Row className="mb-3">

                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Início de vigência do contrato <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="date"
                                    required
                                    onChange={handleDataVigenciaInicio}
                                    value={dataVigenciaInicio}
                                    placeholder='Data de início de vigência do contrato'
                                />

                            </InputGroup>
                        </Form.Group>
                        
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Término da vigência do contrato <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="date"
                                    required
                                    onChange={handleDataVigenciaFim}
                                    value={dataVigenciaFim}
                                    placeholder='Data do término da vigência do contrato'
                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Identificador do contrato no Cadastro </Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Ex: 000.00-000 do contrato no Cadastro"
                                    required
                                    onChange={handleIdentificadorCipi}
                                    value={identificadorCipi}

                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Url com Dados do contrato </Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="contrato no sistema de Cadastro"
                                    required
                                    onChange={handleUrlCipi}
                                    value={urlCipi}

                                />

                            </InputGroup>
                        </Form.Group>
                    </Row>


                    <Button type="submit">Cadastrar</Button>
                </Form>
            </div>

        </div>
    );
}

export default FormularioContratoEdite;
