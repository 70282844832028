import { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import './dados-modules.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Swal from 'sweetalert2';


function DadosDelete({ id, tipoDocumentoNome, url, cnpjCompra, anoContrato, sequencialcontrato }) {

    const token = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYmE3OTcxOS1lMDlkLTRmNzItODczYS1iMzJhNTlkYjU4MzciLCJleHAiOjE2OTQ2Mzc5ODYsImFkbWluaXN0cmFkb3IiOmZhbHNlLCJjcGZDbnBqIjoiMzAwMTYyNjQwMDAxMDIiLCJlbWFpbCI6ImNvbnRhdG9AZG9pc3JzaXN0ZW1hcy5jb20uYnIiLCJpZEJhc2VEYWRvcyI6OTQyLCJub21lIjoiRE9JU1IgU0VSVklDT1MgREUgREVTRU5WT0xWSU1FTlRPIEUgQ09OU1VMVE9SSUEgREUgU0lTVEVNQSBMVERBIC8gRG9pc1IgU2lzdGVtYXMifQ.a5iS5qx3J6ILUAUtsE4z9aHMJWsxYOPITH86ZPoEIT92iEB0JNDqK2MHwRPcq9fwf1NujEdTY7gO73kfndfOaQ'
   



    const handleSubmitDelete = async () => {


        const instance = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpjCompra}`, // Substitua pela URL do seu servidor
            timeout: 10000, // Tempo limite da solicitação
            headers: {

                'Authorization': token,
                'Accept': '*/*',

            },
        })

        try {
            await instance.delete(`/compras/${anoContrato}/${sequencialcontrato}/arquivos/${id}`, {


            })
                .then(async res => {

                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Excluido com Sucesso!',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    console.log(res)
                    window.location.reload()



                })
        } catch (error) {

            console.log(error)

            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
            })



        };


    }

    return (
        <>


            <tr>
                <td className='doc'>{id}</td>
                <td className='tipo'>{tipoDocumentoNome}</td>
                <td colSpan={6} className='url-aquivo'>{url}</td>
                <Button className='delete-edital-table' onClick={handleSubmitDelete} variant="danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                </svg></Button>{' '}
            </tr>


        </>




    );
}

export default DadosDelete;
