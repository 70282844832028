import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import '../form-modules.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'


function FormularioUpdateCompra() {

    const [codigoUnidade, setCodigoUnidade] = useState('');
    const [tipoInstrumento, setTipoInstrumento] = useState('');
    const [modalidade, setModalidade] = useState('');
    const [modoDisputa, setModoDisputa] = useState('');
    const [numeroCompra, setNumeroCompra] = useState('');
    const [numeroProcesso, setNumeroProcesso] = useState('');
    const [objetoCompra, setObjetoCompra] = useState('');
    const [informacaoComplementar, setInformacaoComplementar] = useState('')
    const [srp, setSrp] = useState(true);
    const [DataAberturaProposta, setDataAberturaProposta] = useState('');
    const [dataEncerramentoProposta, setDataEncerramentoProposta] = useState('');
    const [amparoLegal, setAmparoLegal] = useState('');
    const [linkSistemaOrigem, setLinkSistemaOrigem] = useState('');
    const [justificativa, setJustificativa] = useState('');
    const [justificativaPresencial, setJustificativaPresencial] = useState('');
    const [dataContratacao, setDataContratacao] = useState('');
    const [cnpjOrgaoSubRogado, setCnpjOrgaoSubRogado] = useState('');
    const [codigoUnidadeSubRogada, setCodigoUnidadeSubRogada] = useState('');
    const [anoCompra, setAnoCompra] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [NumeroSequencial, setNumeroSequencial ] = useState('');
    const [situacaoCompra, setSituacaoCompraId ] = useState('');
    
    const [validated, setValidated] = useState(false);


    const handleCnpj = (e) => {
        setCnpj(e.target.value)
    }

    const handleJustificativa = (e) => {
        setJustificativa(e.target.value)
    }


    const handleSituacaoCompra = (e) =>{
        setSituacaoCompraId(e.target.value)

    }

    const handleCnpjOrgaoSubRogado = (e) => {
        setCnpjOrgaoSubRogado(e.target.value)
    }

    const handleCodigoUnidadeSubRogada = (e) => {
        setCodigoUnidadeSubRogada(e.target.value)
    }

    
    const handleJustificativaPresencial = (e) => {
        setJustificativaPresencial(e.target.value)
    }

    const handleDataContarcao = (e) => {
        setDataContratacao(e.target.value)
    }

    const handleNumeroSequencial = (e) => {
        setNumeroSequencial(e.target.value)
    }

    const handleCodigoUnidade = (e) => {
        setCodigoUnidade(e.target.value)
    }

    const handleTipoInstrumento = (e) => {
        setTipoInstrumento(e.target.value)
    }

    const handleModalidade = (e) => {
        setModalidade(e.target.value)
    }

    const handleModoDisputa = (e) => {
        setModoDisputa(e.target.value)
    }

    const handleNumeroCompra = (e) => {
        setNumeroCompra(e.target.value)
    }

    const handleAnoCompra = (e) => {
        setAnoCompra(e.target.value)
    }

    const handleNumeroProcesso = (e) => {
        setNumeroProcesso(e.target.value)
    }

    const handleObjetoCompra = (e) => {
        setObjetoCompra(e.target.value)
    }

    const handleInformacoesComplementar = (e) => {
        setInformacaoComplementar(e.target.value)
    }

    const handleSrp = (e) => {
        setSrp(e.target.value)
    }

    const handleDataAberturaProposta = (e) => {
        setDataAberturaProposta(e.target.value)
        console.log(e.target.value)

    }

    const handleDataEncerramentoProposta = (e) => {
        setDataEncerramentoProposta(e.target.value)
    }

    const handleAmparoLegal = (e) => {
        setAmparoLegal(e.target.value)
    }

    const handleLinkSistemaOrigem = (e) => {
        setLinkSistemaOrigem(e.target.value)
    }



    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);


        const instance = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpj}`, // Substitua pela URL do seu servidor
            timeout: 10000, // Tempo limite da solicitação
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYmE3OTcxOS1lMDlkLTRmNzItODczYS1iMzJhNTlkYjU4MzciLCJleHAiOjE2OTI3MTgxNDYsImFkbWluaXN0cmFkb3IiOmZhbHNlLCJjcGZDbnBqIjoiMzAwMTYyNjQwMDAxMDIiLCJlbWFpbCI6ImNvbnRhdG9AZG9pc3JzaXN0ZW1hcy5jb20uYnIiLCJpZEJhc2VEYWRvcyI6OTQyLCJub21lIjoiRE9JU1IgU0VSVklDT1MgREUgREVTRU5WT0xWSU1FTlRPIEUgQ09OU1VMVE9SSUEgREUgU0lTVEVNQSBMVERBIC8gRG9pc1IgU2lzdGVtYXMifQ.ISe95pr47kguV7gvcveoFb9e6IINcCGiSi2yjLBqhBMfoowXm-YRkv_OuzFH2ayCrCW538nUv6zROe345185Sw`,
                'Accept': '*/*',

            },
        })


        try {
            await instance.put(`/compras/${anoCompra}/${NumeroSequencial}`, {

                
                    tipoInstrumentoConvocatorioId: tipoInstrumento,
                    codigoUnidadeCompradora: codigoUnidade,
                    modalidadeId: modalidade,
                    modoDisputaId: modoDisputa,
                    numeroCompra: numeroCompra,
                    numeroProcesso: numeroProcesso,
                    situacaoCompraId: situacaoCompra,
                    objetoCompra: objetoCompra,
                    informacaoComplementar: informacaoComplementar,
                    cnpjOrgaoSubRogado: cnpjOrgaoSubRogado,
                    codigoUnidadeSubRogada: codigoUnidadeSubRogada,
                    srp: true,
                    dataAberturaProposta: `${DataAberturaProposta}:00`,
                    dataEncerramentoProposta: `${dataEncerramentoProposta}:00`,
                    amparoLegalId:amparoLegal,
                    linkSistemaOrigem: linkSistemaOrigem,
                    justificativaPresencial: justificativaPresencial,
                    justificativa: justificativa
                   
                   
               
            })
                .then( async res => {


                   await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Campra Editada com Sucesso!',
                        showConfirmButton: false,
                        timer: 1500
                      })

                      console.log(res)


                })
        } catch (error) {

            console.log(error)
            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
              })

        };


    }



    return (
        <div className="form">



            <div className='Dados-Title'>

                <h1>Editar Compra</h1>
                <h3> CÂMARA MUNICIPAL DE CIDADE - BAHIA  <img src='' /> </h3>

            </div>

            <hr />

            <div className='form-create'>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <Row className="mb-3">

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Modalidade <b>*</b></Form.Label>
                            <Form.Select required aria-label="Default select example" onChange={handleModalidade} value={modalidade}>
                                <option selected value={""}>Selecione</option>
                                <option value="1"> Leilão - Eletrônico</option>
                                <option value="2"> Diálogo Competitivo</option>
                                <option value="3"> Concurso</option>
                                <option value="4"> Concorrência - Eletrônica</option>
                                <option value="5"> Concorrência - Presencial</option>
                                <option value="6"> Pregão - Eletrônico</option>
                                <option value="7"> Pregão - Presencial</option>
                                <option value="8"> Dispensa de Licitação</option>
                                <option value="9"> Inexigibilidade</option>
                                <option value="10"> Manifestação de Interesse</option>
                                <option value="11"> Pré-qualificação</option>
                                <option value="12"> Credenciamento</option>
                                <option value="13"> Leilão - Presencial</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Amparo Legal <b>*</b></Form.Label>
                            <Form.Select required aria-label="Default select example" onChange={handleAmparoLegal} value={amparoLegal}>
                                <option selected value={""}>Selecione</option>

                                <option value="1">Lei 14.133/2021, Art. 28, I</option>
                                <option value="2">Lei 14.133/2021, Art. 28, II</option>
                                <option value="3">Lei 14.133/2021, Art. 28, III</option>
                                <option value="4">Lei 14.133/2021, Art. 28, IV</option>
                                <option value="5">Lei 14.133/2021, Art. 28, V</option>
                                <option value="6">Lei 14.133/2021, Art. 74, I</option>
                                <option value="7">Lei 14.133/2021, Art. 74, II</option>
                                <option value="8">Lei 14.133/2021, Art. 74, III, a</option>
                                <option value="9">Lei 14.133/2021, Art. 74, III, b</option>
                                <option value="10">Lei 14.133/2021, Art. 74, III, c</option>
                                <option value="11">Lei 14.133/2021, Art. 74, III, d</option>
                                <option value="12">Lei 14.133/2021, Art. 74, III, e</option>
                                <option value="13">Lei 14.133/2021, Art. 74, III, f</option>
                                <option value="14">Lei 14.133/2021, Art. 74, III, g</option>
                                <option value="15">Lei 14.133/2021, Art. 74, III, h</option>
                                <option value="16">Lei 14.133/2021, Art. 74, IV</option>
                                <option value="17">Lei 14.133/2021, Art. 74, V</option>
                                <option value="18">Lei 14.133/2021, Art. 75, I</option>
                                <option value="19">Lei 14.133/2021, Art. 75, II</option>
                                <option value="20">Lei 14.133/2021, Art. 75, III, a</option>
                                <option value="21">Lei 14.133/2021, Art. 75, III, b</option>
                                <option value="22">Lei 14.133/2021, Art. 75, IV, a</option>
                                <option value="23">Lei 14.133/2021, Art. 75, IV, b</option>
                                <option value="24">Lei 14.133/2021, Art. 75, IV, c</option>
                                <option value="25">Lei 14.133/2021, Art. 75, IV, d</option>
                                <option value="26">Lei 14.133/2021, Art. 75, IV, e</option>
                                <option value="27">Lei 14.133/2021, Art. 75, IV, f</option>
                                <option value="28">Lei 14.133/2021, Art. 75, IV, g</option>
                                <option value="29">Lei 14.133/2021, Art. 75, IV, h</option>
                                <option value="30">Lei 14.133/2021, Art. 75, IV, i</option>
                                <option value="31">Lei 14.133/2021, Art. 75, IV, j</option>
                                <option value="32">Lei 14.133/2021, Art. 75, IV, k</option>
                                <option value="33">Lei 14.133/2021, Art. 75, IV, l</option>
                                <option value="34">Lei 14.133/2021, Art. 75, IV, m</option>
                                <option value="35">Lei 14.133/2021, Art. 75, V</option>
                                <option value="36">Lei 14.133/2021, Art. 75, VI</option>
                                <option value="37">Lei 14.133/2021, Art. 75, VII</option>
                                <option value="38">Lei 14.133/2021, Art. 75, VIII</option>
                                <option value="39">Lei 14.133/2021, Art. 75, IX</option>
                                <option value="40">Lei 14.133/2021, Art. 75, X</option>
                                <option value="41">Lei 14.133/2021, Art. 75, XI</option>
                                <option value="42">Lei 14.133/2021, Art. 75, XII</option>
                                <option value="43">Lei 14.133/2021, Art. 75, XIII</option>
                                <option value="44">Lei 14.133/2021, Art. 75, XIV</option>
                                <option value="45">Lei 14.133/2021, Art. 75, XV</option>
                                <option value="46">Lei 14.133/2021, Art. 75, XVI</option>
                                <option value="47">Lei 14.133/2021, Art. 78, I</option>
                                <option value="48">Lei 14.133/2021, Art. 78, II</option>
                                <option value="49">Lei 14.133/2021, Art. 78, III</option>
                                <option value="50">Lei 14.133/2021, Art. 74, caput</option>
                                <option value="51">Lei 14.284/2021, Art. 29, caput</option>
                                <option value="52">Lei 14.284/2021, Art. 24 § 1º</option>
                                <option value="53">Lei 14.284/2021, Art. 25 § 1º</option>
                                <option value="54">Lei 14.284/2021, Art. 34</option>
                                <option value="55">Lei 9.636/1998, Art. 11-C, I</option>
                                <option value="56">Lei 9.636/1998, Art. 11-C, II</option>
                                <option value="57">Lei 9.636/1998, Art. 24-C, I</option>
                                <option value="58">Lei 9.636/1998, Art. 24-C, II</option>
                                <option value="59">Lei 9.636/1998, Art. 24-C, III</option>


                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Modo de Disputa <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Select required aria-label="Default select example" onChange={handleModoDisputa} value={modoDisputa}>
                                    <option selected value={""}>Selecione</option>

                                    <option value="1">Aberto</option>
                                    <option value="2">Fechado</option>
                                    <option value="3">Aberto-Fechado</option>
                                    <option value="4">Dispensa Com Disputa</option>
                                    <option value="5">Não se aplica</option>
                                    <option value="6">Fechado-Aberto</option>

                                </Form.Select>

                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Tipo Instrumental Convocatório <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Select required aria-label="Default select example" onChange={handleTipoInstrumento} value={tipoInstrumento}>
                                    <option selected value={""}>Selecione</option>

                                    <option value="1">Edital</option>
                                    <option value="2">Aviso de Contratação Direta</option>
                                    <option value="3">Ato que autoriza a Contratação Direta</option>
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Número da Compra <b>*</b></Form.Label>
                            <Form.Control type="text" placeholder=" número gerado sistema origem" required onChange={handleNumeroCompra} value={numeroCompra} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Ano Compra <b>*</b></Form.Label>
                            <Form.Control type="number" placeholder="2023" required onChange={handleAnoCompra} value={anoCompra} />

                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Abertura de Proposta <b>*</b></Form.Label>
                            <Form.Control type="datetime-local" required onChange={handleDataAberturaProposta} value={DataAberturaProposta} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>Encerramento de Proposta <b>*</b></Form.Label>
                            <Form.Control type="datetime-local" required onChange={handleDataEncerramentoProposta} value={dataEncerramentoProposta} />

                        </Form.Group>

                    </Row>
                    <Row className="mb-3">

                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Data de Contratação</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="datetime-local" required onChange={handleDataContarcao} value={dataContratacao} />

                            </InputGroup>
                        </Form.Group>

                        
                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Número do Processo <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="text" placeholder="Número processo de contratação" onChange={handleNumeroProcesso} value={numeroProcesso} required />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>CNPJ <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="text" placeholder="Número processo de contratação" onChange={handleCnpj} value={cnpj} required />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Numero Sequencial <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="text" placeholder="Número processo de contratação" onChange={handleNumeroSequencial} value={NumeroSequencial} required />
                            </InputGroup>
                        </Form.Group>

                       

                    </Row>
                    <Row className="mb-3">
                        
                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <Form.Label>Link Certamen</Form.Label>
                            <Form.Control type="text" placeholder="URL para página/portal do sistema contratação" onChange={handleLinkSistemaOrigem} value={linkSistemaOrigem} required />

                        </Form.Group>
                        <Form.Group className="mb-3" as={Col} md="3" controlId="validationCustom04">
                            <Form.Check
                                required
                                label="Sistema de Registro de Preços"
                                feedbackType="invalid"
                                onChange={handleSrp} value={srp} />
                        </Form.Group>

                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <Form.Label>CNPJ Orgão SubRogada</Form.Label>
                            <Form.Control type="text" placeholder="CNPJ" required onChange={handleCnpjOrgaoSubRogado} value={cnpjOrgaoSubRogado} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                            <Form.Label>Codigo Unidade SubRogada <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="text" placeholder="proprietário da contratação ou alienação" onChange={handleCodigoUnidadeSubRogada} value={codigoUnidadeSubRogada} required />

                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                            <Form.Label>Codigo Unidade Compradora <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="text" placeholder="proprietário da contratação ou alienação" onChange={handleCodigoUnidade} value={codigoUnidade} required />

                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                            <Form.Label>Situação da Contratação <b>*</b></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Select required aria-label="Default select example" onChange={handleSituacaoCompra} value={situacaoCompra}>
                                    <option selected value={""}>Selecione</option>

                                    <option value="1">Divulgada no PNCP</option>
                                    <option value="2">Revogada</option>
                                    <option value="3">Anulada</option>
                                    <option value="4">Suspensa</option>
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                        

                    </Row>

                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Objeto da Compra <b>*</b></Form.Label>
                        <Form.Control type="text" className='options-text' rows={3} onChange={handleObjetoCompra} value={objetoCompra} required />

                    </Form.Group>


                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Informação Complementar</Form.Label>
                        <Form.Control type="text" className='options-text' rows={3} onChange={handleInformacoesComplementar} value={informacaoComplementar} required />

                    </Form.Group>

                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Justificativa</Form.Label>
                        <Form.Control type="text" className='options-text' rows={3} onChange={handleJustificativa} value={justificativa} required />

                    </Form.Group>

                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Justificativa Presencial</Form.Label>
                        <Form.Control type="text" className='options-text' rows={3} onChange={handleJustificativaPresencial} value={justificativaPresencial}  />

                    </Form.Group>


                    <Button type="submit">Cadastrar</Button>
                </Form>
            </div>

        </div>
    );
}

export default FormularioUpdateCompra;
