import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import '../form-modules.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'


function FormularioDeleteCompra() {

    const [codigoUnidade, setCodigoUnidade] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [NumeroSequencial, setNumeroSequencial] = useState('');
    const [anoCompra, setAnoCompra] = useState('');
    const [justificativa, setJustificativa] = useState('');

    // const [tipoInstrumento, setTipoInstrumento] = useState('');
    // const [modalidade, setModalidade] = useState('');
    // const [modoDisputa, setModoDisputa] = useState('');
    // const [numeroCompra, setNumeroCompra] = useState('');
    // const [numeroProcesso, setNumeroProcesso] = useState('');
    // const [objetoCompra, setObjetoCompra] = useState('');
    // const [informacaoComplementar, setInformacaoComplementar] = useState('')
    // const [srp, setSrp] = useState(true);
    // const [DataAberturaProposta, setDataAberturaProposta] = useState('');
    // const [dataEncerramentoProposta, setDataEncerramentoProposta] = useState('');
    // const [amparoLegal, setAmparoLegal] = useState('');
    // const [linkSistemaOrigem, setLinkSistemaOrigem] = useState('');
    // const [justificativaPresencial, setJustificativaPresencial] = useState('');
    // const [dataContratacao, setDataContratacao] = useState('');
    // const [cnpjOrgaoSubRogado, setCnpjOrgaoSubRogado] = useState('');
    // const [codigoUnidadeSubRogada, setCodigoUnidadeSubRogada] = useState('');

    // const [situacaoCompra, setSituacaoCompraId ] = useState('');

    const [validated, setValidated] = useState(false);


    const handleCnpj = (e) => {
        setCnpj(e.target.value)
    }

    const handleJustificativa = (e) => {
        setJustificativa(e.target.value)
    }
    const handleCodigoUnidade = (e) => {
        setCodigoUnidade(e.target.value)
    }
    const handleAnoCompra = (e) => {
        setAnoCompra(e.target.value)
    }

    const handleNumeroSequencial = (e) => {
        setNumeroSequencial(e.target.value)
    }



    // const handleSituacaoCompra = (e) =>{
    //     setSituacaoCompraId(e.target.value)

    // }

    // const handleCnpjOrgaoSubRogado = (e) => {
    //     setCnpjOrgaoSubRogado(e.target.value)
    // }

    // const handleCodigoUnidadeSubRogada = (e) => {
    //     setCodigoUnidadeSubRogada(e.target.value)
    // }


    // const handleJustificativaPresencial = (e) => {
    //     setJustificativaPresencial(e.target.value)
    // }

    // const handleDataContarcao = (e) => {
    //     setDataContratacao(e.target.value)
    // }

   


    // const handleTipoInstrumento = (e) => {
    //     setTipoInstrumento(e.target.value)
    // }

    // const handleModalidade = (e) => {
    //     setModalidade(e.target.value)
    // }

    // const handleModoDisputa = (e) => {
    //     setModoDisputa(e.target.value)
    // }

    // const handleNumeroCompra = (e) => {
    //     setNumeroCompra(e.target.value)
    // }



    // const handleNumeroProcesso = (e) => {
    //     setNumeroProcesso(e.target.value)
    // }

    // const handleObjetoCompra = (e) => {
    //     setObjetoCompra(e.target.value)
    // }

    // const handleInformacoesComplementar = (e) => {
    //     setInformacaoComplementar(e.target.value)
    // }

    // const handleSrp = (e) => {
    //     setSrp(e.target.value)
    // }

    // const handleDataAberturaProposta = (e) => {
    //     setDataAberturaProposta(e.target.value)
    //     console.log(e.target.value)

    // }

    // const handleDataEncerramentoProposta = (e) => {
    //     setDataEncerramentoProposta(e.target.value)
    // }

    // const handleAmparoLegal = (e) => {
    //     setAmparoLegal(e.target.value)
    // }

    // const handleLinkSistemaOrigem = (e) => {
    //     setLinkSistemaOrigem(e.target.value)
    // }



    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);


        const instance = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/v1/orgaos/${cnpj}`, // Substitua pela URL do seu servidor
            timeout: 10000, // Tempo limite da solicitação
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYmE3OTcxOS1lMDlkLTRmNzItODczYS1iMzJhNTlkYjU4MzciLCJleHAiOjE2OTI3MTgxNDYsImFkbWluaXN0cmFkb3IiOmZhbHNlLCJjcGZDbnBqIjoiMzAwMTYyNjQwMDAxMDIiLCJlbWFpbCI6ImNvbnRhdG9AZG9pc3JzaXN0ZW1hcy5jb20uYnIiLCJpZEJhc2VEYWRvcyI6OTQyLCJub21lIjoiRE9JU1IgU0VSVklDT1MgREUgREVTRU5WT0xWSU1FTlRPIEUgQ09OU1VMVE9SSUEgREUgU0lTVEVNQSBMVERBIC8gRG9pc1IgU2lzdGVtYXMifQ.ISe95pr47kguV7gvcveoFb9e6IINcCGiSi2yjLBqhBMfoowXm-YRkv_OuzFH2ayCrCW538nUv6zROe345185Sw`,
                'Accept': '*/*',

            },
        })


        try {
            await instance.delete(`/compras/${anoCompra}/${NumeroSequencial}`, {


                justificativa: justificativa



            })
                .then(async res => {


                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Campra Excluida com Sucesso!',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    console.log(res)


                })
        } catch (error) {

            console.log(error)
            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Ops.. Algo saiu errado!`,
                text: `${error.response.data.message}`,
                showConfirmButton: false,
                timer: 4000
            })

        };


    }



    return (
        <div className="form">



            <div className='Dados-Title'>

                <h1>Exclusão de Compra</h1>
                <h3> CÂMARA MUNICIPAL DE CIDADE - BAHIA  <img src='' /> </h3>

            </div>

            <hr />

            <div className='form-create'>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3"> 

                    <Form.Group as={Col} md="3" controlId="validationCustom02">
                        <Form.Label>Ano Compra <b>*</b></Form.Label>
                        <Form.Control type="number" placeholder="2023" required onChange={handleAnoCompra} value={anoCompra} />

                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                        <Form.Label>CNPJ <b>*</b></Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control type="text" placeholder="Número processo de contratação" onChange={handleCnpj} value={cnpj} required />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} md="3" controlId="validationCustomUsername">
                        <Form.Label>Numero Sequencial <b>*</b></Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control type="text" placeholder="Número processo de contratação" onChange={handleNumeroSequencial} value={NumeroSequencial} required />
                        </InputGroup>
                    </Form.Group>

                    </Row>

                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Justificativa</Form.Label>
                        <Form.Control type="text" className='options-text' rows={3} onChange={handleJustificativa} value={justificativa} required />

                    </Form.Group>


                    <Button type="submit">Cadastrar</Button>
                </Form>
            </div>

        </div>
    );
}

export default FormularioDeleteCompra;
