import Table from 'react-bootstrap/Table';

import './form-modules.css';
import { useEffect, useState } from 'react';

function FormularioTable() {

    

   
    

    return (
        <div className="form-table">

            

            <Table striped>
                <thead>

                    
                    <tr>
                    <th>Numero Item</th>
                    <th>Descrição</th>
                    <th>Quantidade</th>
                    <th>Unidade Medida</th>
                    <th>Valor Total</th>
                    </tr>
                    
                   
                </thead>
                <tbody>

                    <tr>

                        <th>ddd</th>
                        <th>ddd</th>
                        <th>ddd</th>
                        <th>ddd</th>
                        <th>ddd</th>
                        
                    </tr>



                </tbody>

            </Table>

        </div>
    );
}

export default FormularioTable;
